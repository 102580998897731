<script setup lang="ts">
  // モーダルなエラーダイアログ

  interface Props {
    message: string;
    buttonTitle?: string;
    isOpen: boolean;
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため
  }
  const props = withDefaults(defineProps<Props>(), {
    message: "エラーが発生しました。",
    buttonTitle: "閉じる",
    isOpen: false,
  });

  interface Emits {
    (event: "clickClose"): void;
    (event: "update:isOpen", isOpen: boolean): void;
  }

  const emit = defineEmits<Emits>();

  const clickClose = (event: Event) => {
    event.stopPropagation();
    emit("clickClose");
  };
  const computedIsOpen = computed<boolean>({
    get: () => props.isOpen,
    set: value => {
      emit("update:isOpen", value);
    },
  });
</script>

<template>
  <v-dialog v-model="computedIsOpen" scrim="black" persistent :attach="!!isTest">
    <v-sheet class="dialog py-8 px-10 rounded" color="monochrome-04" align="center">
      <div class="text-dialog-title">{{ message }}</div>

      <div class="d-flex justify-space-evenly">
        <AtomsDialogButton class="button mt-5" @click="clickClose">
          {{ buttonTitle }}
        </AtomsDialogButton>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .dialog {
    min-height: 146px;
    min-width: 345px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
</style>
