// トークルームユーザーデータ
export class TalkRoomUser {
  constructor(
    public userId: string,
    public userName: string,
    public userImage: string,
    public iconPath: string = "",
    public isOwner: boolean = false,
  ) {}
}

// Firestore data converter
export const TalkRoomUserConverter = {
  toFirestore: (item: any) => {
    return {
      UserId: item.userId,
      UserName: item.userName,
      IconPath: item.userImage,
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new TalkRoomUser(data.UserId, data.UserName, data.IconPath);
  },
};
