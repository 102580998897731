export const featureFirestoreCache = (): boolean => {
  // フィーチャートグル: Firestore Cache 有効/無効判定
  return useRuntimeConfig().public.ENABLE_FIRESTORE_CACHE === "true";
};

export const featureConversion = (): boolean => {
  // フィーチャートグル: サンクスページ
  return useRuntimeConfig().public.ENABLE_CONVERSION === "true";
};

export const featurePlanMemberCountSetting = (): boolean => {
  // フィーチャートグル: 講座設定画面で会員数設定を表示するかどうか
  return useRuntimeConfig().public.ENABLE_PLAN_MEMBER_COUNT_SETTING === "true";
};

export const featurePriceSetting = (): boolean => {
  // フィーチャートグル: ENABLE_PRICE_SETTING が true の場合、価格設定機能を有効にする
  return useRuntimeConfig().public.ENABLE_PRICE_SETTING === "true";
};

export const featureChangePrice = (): boolean => {
  // フィーチャートグル: 価格変更
  return useRuntimeConfig().public.ENABLE_CHANGE_PRICE === "true";
};

export const featureOneTimeOffer = (): boolean => {
  // フィーチャートグル: ワンタイムオファー
  return useRuntimeConfig().public.ENABLE_ONE_TIME_OFFER === "true";
};

export const featureAllPlanManage = (): boolean => {
  // フィーチャートグル: 全講座管理
  return useRuntimeConfig().public.ENABLE_ALL_PLAN_MANAGE === "true";
};

export const featureBulkDelivery = (): boolean => {
  // フィーチャートグル: 一斉配信
  return useRuntimeConfig().public.ENABLE_BULK_DELIVERY === "true";
};

// ENABLE_OWNER_JOIN
export const featureOwnerJoin = (): boolean => {
  // フィーチャートグル: オーナーが講座に入会する
  return useRuntimeConfig().public.ENABLE_OWNER_JOIN === "true";
};

export const featureTalkBookmark = (): boolean => {
  // フィーチャートグル: トークブックマーク
  return useRuntimeConfig().public.ENABLE_TALK_BOOKMARK === "true";
};

export const featureTalkMention = (): boolean => {
  // フィーチャートグル: トークメンション
  return useRuntimeConfig().public.ENABLE_TALK_MENTION === "true";
};

// ENABLE_ALL_USER_MANAGE
export const featureAllUserManage = (): boolean => {
  // フィーチャートグル: 会員一覧での全ユーザー管理
  return useRuntimeConfig().public.ENABLE_ALL_USER_MANAGE === "true";
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    featureFirestoreCache: () => boolean;
    featureConversion: () => boolean;
    featurePlanMemberCountSetting: () => boolean;
    featurePriceSetting: () => boolean;
    featureChangePrice: () => boolean;
    featureOneTimeOffer: () => boolean;
    featureAllPlanManage: () => boolean;
    featureBulkDelivery: () => boolean;
    featureOwnerJoin: () => boolean;
    featureTalkMention: () => boolean;
    featureTalkBookmark: () => boolean;
    featureAllUserManage: () => boolean;
  }
}
