import _ from "lodash";
import { defineStore } from "pinia";
import { useUserStore } from "./UserStore";
import { TalkRoom } from "~/entities/TalkRoom";
import { newLoadingTalk, Talk } from "~/entities/Talk";
import { TalkOgp } from "~/entities/TalkOgp";
import { TalkRoomUser } from "~/entities/TalkRoomUser";
import type { ITalkRoomRepository } from "~/interfaces/ITalkRoomRepository";
import { TalkRoomRepository } from "~/Repositories/TalkRoomRepository";
import { OgpRepository } from "~/Repositories/OgpRepository";
import { useLoginStore } from "~/stores/LoginStore";
import { usePlanStore } from "~/stores/PlanStore";
import { useErrorStore } from "~/stores/ErrorStore";
import { TALK_ROOM_GROUP_MAX, TALK_ROOM_DM_MAX, TALK_MENTION_USERS_MAX } from "~/consts/TalkConfig";

/**
 * トークルームデータを扱うクラス
 * getTalkでユーザーデータを利用するので、トークルーム変更時には最初にgetUsersを呼ぶこと。
 */
export const useTalkRoomStore = defineStore("TalkRoomStore", () => {
  // 定数
  const ENABLE_PREFETCH = true;
  const USE_CACHE_FOR_ASYNC_DATA = true; // デバッグ時のみ false にしてキャッシュ無効化する
  const DELAY_ASYNC_SEC = 0; // デバッグ時のみ 1 以上にして非同期処理を遅らせる

  // グローバルデータ
  const talkOgpLists = ref<{ [talkId: string]: TalkOgp[] }>({}); // OGP情報のキャッシュ
  const replyTalks = ref<Talk[]>([]); // 返信用のトークのキャッシュ。talksに含まれていない場合はこちらから取得する

  // 講座単位データ
  const selectedPlanId = ref<string>("");
  const planOwners = ref<TalkRoomUser[]>([]);
  const talkRooms = ref<TalkRoom[]>([]);
  const talkRoomsForSetting = ref<TalkRoom[]>([]); // 通知設定用のトークルームリストを別でもつ
  const observeTalkRooms = ref<{ id: string; latesteTalkID: number }[]>([]);

  // トークルーム単位データ
  const selectedTalkRoomId = ref<string>("");
  const talkRoomSetDone = ref<boolean>(false);
  const talks = ref<Talk[]>([]);
  const prefetchedTalksBefore = ref<Talk[]>([]);
  const prefetchedTalksAfter = ref<Talk[]>([]);
  const replyTalkToEdit = ref<Talk | null>(null); // 編集中の返信トーク
  const talkRoomUsers = ref<{ [id: string]: TalkRoomUser }>({});
  const userGoodList = ref<{ [id: string]: string[] }>({});
  const hasPrevTalk = ref<boolean>(false);
  const hasNextTalk = ref<boolean>(false);
  const loading = ref(false);
  const updating = ref(false);
  const updateTalk = ref(false); // talkのlengthは監視できるが中身の変更は監視できないのでフラグを立てる

  // トークルーム初回データクエリ → 表示 → スクロール完了判定
  const initRenderingFinished = ref(false);

  // 検索状態
  const canSearch = ref(false);
  const searchOpen = ref(false);
  const searchText = ref("");

  const repository: ITalkRoomRepository = new TalkRoomRepository();
  const ogpRepository = new OgpRepository();

  const loginStore = useLoginStore();
  const planStore = usePlanStore();
  const userStore = useUserStore();
  const errorStore = useErrorStore();

  const ogpRequestCached = new Map(); // URLごとのブラウザキャッシュ有無
  const ogpRequestDebounces = new Map(); // トークxURLごとのデバウンス
  const replyRequestDebounces = new Map(); // トークごとのデバウンス

  const delayAsyncForDebug = async () => {
    if (DELAY_ASYNC_SEC > 0) {
      await new Promise(resolve => setTimeout(resolve, DELAY_ASYNC_SEC * 1000));
    }
  };

  // トーク監視状態
  let talksObserveInitiated = false;
  let talksObserveAddAfter: string | undefined;
  let talksObserveChangeFrom: string | undefined;
  let talksObserveChangeTo: string | undefined;

  // 講座初期化
  const initPlan = (planId: string) => {
    // 講座が変わった場合のみ講師リストを初期化
    if (selectedPlanId.value !== planId) {
      planOwners.value = [];
    }

    selectedPlanId.value = planId;
  };

  // トークルーム初期化
  const initTalkRoom = (talkRoomId: string) => {
    selectedTalkRoomId.value = talkRoomId;
    talkRoomSetDone.value = false;
    initRenderingFinished.value = false;
    ogpRequestDebounces.clear();
    replyRequestDebounces.clear();

    resetTalks();
    initTalksObserve();
  };

  // トークデータ初期化
  const resetTalks = () => {
    hasPrevTalk.value = false;
    hasNextTalk.value = false;
    talks.value = [];
    updateTalk.value = false;
    prefetchedTalksBefore.value = [];
    prefetchedTalksAfter.value = [];
  };

  // 未読フラグを設定する
  const setUnreadFlag = (talkRoomId: string, unreadFlag: boolean) => {
    const talkRoom = talkRooms.value.find(item => item.talkRoomId === talkRoomId);
    if (!talkRoom || !planStore.plan) {
      return;
    }
    // トークルームの未読フラグを設定する
    talkRoom.hasUnread = unreadFlag;
    // 講座ページのトークタブの未読フラグも設定する
    planStore.plan.hasUnreadTalk = talkRooms.value.some(item => item.hasUnread);
  };

  // トークルームのデータ監視を行う。ここではlatesteTalkIDの監視のみでトークデータの監視はしない。
  const startTalkRoomsObserve = (planId: string) => {
    loading.value = true;
    observeTalkRooms.value = [];
    try {
      repository.startTalkRoomsObserve(planId, addTalkRoomCallback, changeTalkRoomCallback);
    } finally {
      loading.value = false;
    }
  };

  const stopTalkRoomsObserve = () => {
    loading.value = true;
    try {
      repository.stopTalkRoomsObserve();
    } finally {
      loading.value = false;
      clearTalkRoom();
    }
  };

  const clearTalkRoom = () => {
    talkRooms.value = [];
  };

  const startUsersObserve = (planId: string) => {
    repository.startUsersObserve(planId, addUsersCallback, changeUsersCallback);
  };

  const stopUsersObserve = () => {
    repository.stopUsersObserve();
  };

  // トークデータ監視初期化
  const initTalksObserve = () => {
    talksObserveInitiated = false;
    talksObserveAddAfter = undefined;
    talksObserveChangeFrom = undefined;
    talksObserveChangeTo = undefined;
  };

  // トークデータの監視を行う
  const startTalksObserve = (
    planId: string,
    talkRoomId: string,
    includeAdd: boolean,
    addAfterTalkId: string | undefined,
    changeFromTalkId: string | undefined,
    changeToTalkId: string | undefined,
  ) => {
    // トークデータ監視は重いので、条件が同じ場合は監視しないようにチェックを入れる
    if (
      talksObserveInitiated &&
      talksObserveAddAfter === addAfterTalkId &&
      talksObserveChangeFrom === changeFromTalkId &&
      talksObserveChangeTo === changeToTalkId
    ) {
      // 2回目以降かつ前回と同じ監視条件の場合は監視しない
      return;
    }
    // 監視状態を設定
    talksObserveInitiated = true;
    talksObserveAddAfter = addAfterTalkId;
    talksObserveChangeFrom = changeFromTalkId;
    talksObserveChangeTo = changeToTalkId;

    loading.value = true;
    try {
      repository.startTalksObserve(
        planId,
        talkRoomId,
        includeAdd ? addCallback : undefined,
        addAfterTalkId,
        changeCallback,
        changeFromTalkId,
        changeToTalkId,
        () => {
          talkRoomSetDone.value = true;
        },
      );
    } finally {
      loading.value = false;
    }
  };

  const stopTalksObserve = () => {
    repository.stopTalksObserve();
  };

  // トークルームにてユーザーのいいね済みリスト監視を行う
  const startUserGoodsObserve = async (talkRoomId: string) => {
    if (!loginStore.isLogin()) return; // ログインしていない場合は何もしない
    try {
      await repository.startUserGoodsObserve(loginStore.loginInfo!.userId, talkRoomId, (type: string, id: string) => {
        if (!userGoodList.value[talkRoomId]) {
          userGoodList.value[talkRoomId] = [];
        }
        if (type === "added") {
          userGoodList.value[talkRoomId].push(id);
        } else if (type === "removed") {
          userGoodList.value[talkRoomId] = userGoodList.value[talkRoomId].filter(item => item !== id);
        }
      });
    } finally {
      /* empty */
    }
  };

  const stopUserGoodsObserve = () => {
    repository.stopUserGoodsObserve();
  };

  const setUpdateTalkFlag = () => {
    updateTalk.value = true;
  };

  const clearUpdateTalkFlag = () => {
    updateTalk.value = false;
  };

  const updateTalkValue = (talkId: string, updateFunc: (talk: Talk) => void) => {
    const talk = talks.value.find(item => item.talkId === talkId);
    if (!talk) {
      return false;
    }
    updateFunc(talk);
    setUpdateTalkFlag();
    return true;
  };

  // api
  const getTalkRooms = async (planId: string) => {
    try {
      talkRooms.value = await repository.getTalkRooms(planId);
    } catch (error: any) {
      errorStore.showConnectionError(`TalkRoomStore.getTalkRooms: ${error}`);
    }
  };

  const getTalkRoomsForSetting = async (planId: string) => {
    try {
      talkRoomsForSetting.value = await repository.getTalkRooms(planId);
    } catch (error: any) {
      errorStore.showConnectionError(`TalkRoomStore.getTalkRoomsForSetting: ${error}`);
    }
  };

  const searchTalkCountsInPlan = async (planId: string, searchWord: string, countMax: number) => {
    const res = await repository.searchTalksByPlan(planId, searchWord, countMax);
    const talkCounts = new Array<{ talkRoomId: string; count: number }>();
    for (const talk of res) {
      // トークルームごとのカウンタを取得 or 作成
      let talkCount = talkCounts.find(item => item.talkRoomId === talk.talkRoomId);
      if (!talkCount) {
        talkCount = {
          talkRoomId: talk.talkRoomId,
          count: 0,
        };
        talkCounts.push(talkCount);
      }
      // カウントアップ
      talkCount.count++;
    }
    return talkCounts;
  };

  const searchTalkIds = async (talkRoomId: string, searchWord: string, countMax: number) => {
    const res = await repository.searchTalksByTalkRoom(talkRoomId, searchWord, countMax);
    return res.map(item => item.talkId);
  };

  const fetchTalkRoomUser = async (talk: Talk) => {
    await updateTalkRoomUsers(selectedPlanId.value, [talk]);
  };

  const getGoodUsers = async (talkId: string, page: number) => {
    return await repository.getGoodUsersFromApi(talkId, page);
  };

  const updateTalkRoomUsers = async (planId: string, talks: Talk[]) => {
    // 指定トークリストから未取得のユーザーIDをユニークに取得
    const userIds = talks.map(t => t.userId); // 投稿者ユーザーID
    const mentionUserIds = talks.flatMap(t => t.mentionUserIds || []); // メンションユーザーID
    const userIdsToQueryTmp = [];
    for (const userId of userIds.concat(mentionUserIds)) {
      if (!(userId in talkRoomUsers.value)) {
        userIdsToQueryTmp.push(userId);
      }
    }
    if (userIdsToQueryTmp.length === 0) {
      return; // すべて取得済みなので何もしない
    }
    const userIdsToQuery = _.uniq(userIdsToQueryTmp);

    // まず Firestore からユーザー情報を取得
    const users = await repository.getUsers(planId, userIdsToQuery);
    if (users?.length) {
      for (const user of users) {
        talkRoomUsers.value[user.userId] = user;
      }
    }

    // Firestore から取得しきれなかったユーザーについては API から取得
    // TODO 性能懸念あり。firestore へのユーザー情報保存形式を要検討
    const remainingUserIds = userIdsToQuery.filter(
      userId =>
        // userName はメンションで必須なので、取得済みであっても userName がない場合は再取得
        !(userId in talkRoomUsers.value) || !talkRoomUsers.value[userId].userName,
    );
    if (remainingUserIds.length > 0) {
      for (const userId of remainingUserIds) {
        await userStore.fetchOther(userId, true);
        const user = userStore.otherUser;
        if (!user) {
          throw new Error(`updateTalkRoomUsers: failed to fetch ${userId}`);
        }
        talkRoomUsers.value[user.userId] = new TalkRoomUser(user.userId, user.userName, user.userImage);
      }
    }
  };

  const getMentionMembers = async (searchTerm: string, count: number): Promise<TalkRoomUser[]> => {
    return await repository.getPlanMembers(selectedPlanId.value, searchTerm, 1, count);
  };

  const getMentionUsers = async (searchTerm: string): Promise<TalkRoomUser[]> => {
    if (!selectedPlanId.value || !selectedTalkRoomId.value) {
      return [];
    }

    // 対象トークルーム決定
    const talkRoom = talkRooms.value.find(t => t.talkRoomId === selectedTalkRoomId.value);
    if (!talkRoom) {
      return [];
    }

    // selectedTalkRoom.value.dmUser は常に存在するため、 userId でチェックする
    const dmUser = talkRoom.dmUser?.userId ? talkRoom.dmUser : undefined;

    // 非公開・アーカイブの場合は講師のみ
    // （そもそもそれであればチャンネル参照できないが、念のためガード）
    const onlyOwners = talkRoom.isArchive || !talkRoom.published;

    // 講師がなければ取得
    if (planOwners.value.length === 0) {
      planOwners.value = await repository.getPlanOwners(selectedPlanId.value);
    }
    // メンションユーザー最大数
    const maxUsers = TALK_MENTION_USERS_MAX;
    // 講師をフィルタリング
    const searchedOwners = searchTerm.length
      ? planOwners.value.filter(o => o.userName.includes(searchTerm))
      : planOwners.value;
    // 最後、自分を差し引く場合に備え 1 人分余裕を持たせる
    const maxMembers = maxUsers - searchedOwners.length + 1;

    // 講師限定であれば会員を取得しない
    // DM の場合はその DM ユーザーのみ取得
    // その他の場合は会員を最大件数取得
    const searchedMembers = onlyOwners ? [] : dmUser ? [dmUser] : await getMentionMembers(searchTerm, maxMembers);

    // 自分を除外したうえで上位を最大数分表示
    return [...searchedOwners, ...searchedMembers]
      .filter(u => loginStore.loginInfo.userId !== u.userId)
      .slice(0, maxUsers);
  };

  // トーク順序が正しいかチェック
  const checkTalksOrder = (talks: Talk[]) => {
    for (let i = 0; i < talks.length - 1; i++) {
      if (Number(talks[i].talkId) >= Number(talks[i + 1].talkId)) {
        return false;
      }
    }
    return true;
  };

  // トーク順序が正しいかチェックして、正しくない場合はソート
  // また重複があれば除去する
  const adjustTalksOrder = (talks: Talk[]) => {
    if (!checkTalksOrder(talks)) {
      consoleWarn(
        `adjustTalksOrder: talks order invalid: room:${selectedTalkRoomId.value}, talks:${talks.map(t => t.talkId)}`,
      );
      // talkId でソート
      talks.sort((a, b) => {
        return Number(a.talkId) - Number(b.talkId);
      });
      // talkId での重複除去
      talks = _.uniqBy(talks, "talkId");
    }
    if (!checkTalksOrder(talks)) {
      sentryErrorLog(
        `adjustTalksOrder: talks order still invalid: room:${selectedTalkRoomId.value}, talks:${talks.map(t => t.talkId)}`,
      );
    }
    return talks;
  };

  // トークユーザーデータ取得済みかチェック
  const checkTalksUsers = (talks: Talk[]) => {
    for (const talk of talks) {
      if (!(talk.userId in talkRoomUsers.value)) {
        return false;
      }
    }
    return true;
  };

  // トークユーザーデータ取得済みかチェックして、なければ取得
  const adjustTalksUsers = async (talks: Talk[]) => {
    if (!checkTalksUsers(talks)) {
      consoleWarn(
        `adjustTalksUsers: talks users missing: room:${selectedTalkRoomId.value}, talks:${talks.map(t => t.talkId)}`,
      );
      for (const talk of talks) {
        if (!(talk.userId in talkRoomUsers.value)) {
          await fetchTalkRoomUser(talk);
        }
      }
    }
    if (!checkTalksUsers(talks)) {
      sentryErrorLog(
        `adjustTalksUsers: talks users still missing: room:${selectedTalkRoomId.value}, talks:${talks.map(t => t.talkId)}`,
      );
    }
  };

  const updateTalks = async (planId: string, newTalks: Talk[]) => {
    await updateTalkRoomUsers(planId, newTalks);

    // トークユーザーデータ取得済みかチェックして、なければ取得
    await adjustTalksUsers(newTalks);

    // 順序が崩れていた場合はソートしてから設定
    talks.value = adjustTalksOrder(newTalks);
  };

  const addOneTalk = async (planId: string, newTalk: Talk) => {
    await updateTalkRoomUsers(planId, [newTalk]);

    // トークユーザーデータ取得済みかチェックして、なければ取得
    await adjustTalksUsers([newTalk]);

    // 順序が崩れていた場合はソートしてから設定
    const talksToCheck = [...talks.value];
    talksToCheck.push(newTalk);
    if (checkTalksOrder(talksToCheck)) {
      talks.value.push(newTalk);
    } else {
      talks.value = adjustTalksOrder(talksToCheck);
    }
  };

  const updateOneTalk = async (planId: string, newTalk: Talk) => {
    await updateTalkRoomUsers(planId, [newTalk]);
    const oldIndex = talks.value.findIndex(item => item.talkId === newTalk.talkId);

    // トークユーザーデータ取得済みかチェックして、なければ取得
    await adjustTalksUsers([newTalk]);

    // 順序が崩れていた場合はソートしてから設定
    const talksToCheck = [...talks.value];
    talksToCheck[oldIndex] = newTalk;
    if (checkTalksOrder(talksToCheck)) {
      talks.value[oldIndex] = newTalk;
    } else {
      talks.value = adjustTalksOrder(talksToCheck);
    }
  };

  const hasTalk = async (planId: string, talkRoomId: string, talkId: string) => {
    return !!(await repository.getTalk(planId, talkRoomId, talkId));
  };

  const getTalksBefore = async (
    planId: string,
    talkRoomId: string,
    talkId: string | undefined,
    pageSize = 20,
    useCacheIfAvailable: boolean,
  ) => {
    let res = null;
    if (prefetchedTalksBefore.value.length > 0) {
      // prefetch されたデータがあればそれを使う
      res = prefetchedTalksBefore.value;
      prefetchedTalksBefore.value = [];
    } else {
      // prefetch されたデータがなければここで今回分をfetch
      res = await repository.getTalks(planId, talkRoomId, talkId, true, false, pageSize, useCacheIfAvailable);
    }

    // 次回分を非同期で prefetch
    if (ENABLE_PREFETCH && res.length > 0) {
      repository
        .getTalks(planId, talkRoomId, res.at(0)!.talkId, true, false, pageSize, useCacheIfAvailable)
        .then(res => {
          prefetchedTalksBefore.value = res;
        });
    }

    // トークリストに連結
    await updateTalks(planId, res.concat(talks.value));
    // 追加のトークが存在するかどうか (論理削除分は無視)
    hasPrevTalk.value = res.filter(t => !t.deleteType).length > 0;

    // トーク情報を付与する
    setTalkInfoFromIdList(
      res.map((item: Talk) => {
        return item.talkId;
      }),
    );
  };

  const getTalksAfter = async (
    planId: string,
    talkRoomId: string,
    talkId: string | undefined,
    pageSize = 20,
    useCacheIfAvailable: boolean,
  ) => {
    let res = null;
    if (prefetchedTalksAfter.value.length > 0) {
      // prefetch されたデータがあればそれを使う
      res = prefetchedTalksAfter.value;
      prefetchedTalksAfter.value = [];
    } else {
      // prefetch されたデータがなければここで今回分をfetch
      res = await repository.getTalks(planId, talkRoomId, talkId, false, false, pageSize, useCacheIfAvailable);
    }

    // 次回分を非同期で prefetch
    if (ENABLE_PREFETCH && res.length > 0) {
      repository
        .getTalks(planId, talkRoomId, res.at(-1)!.talkId, false, false, pageSize, useCacheIfAvailable)
        .then(res => {
          prefetchedTalksAfter.value = res;
        });
    }

    // トークリストに連結
    await updateTalks(planId, talks.value.concat(res));
    // 追加のトークが存在するかどうか (論理削除分は無視)
    hasNextTalk.value = res.filter(t => !t.deleteType).length > 0;

    // トーク情報を付与する
    setTalkInfoFromIdList(
      res.map((item: Talk) => {
        return item.talkId;
      }),
    );
  };

  const getTalksAt = async (
    planId: string,
    talkRoomId: string,
    talkId: string,
    pageSize: number,
    useCacheIfAvailable: boolean,
  ) => {
    // 最初にトークデータ初期化
    resetTalks();

    // talkId 前後のトークを取得して新規に talks へ設定
    const res1 = await repository.getTalks(
      planId,
      talkRoomId,
      talkId,
      true,
      true,
      pageSize / 2 + 1,
      useCacheIfAvailable,
    );
    const res2 = await repository.getTalks(planId, talkRoomId, talkId, false, false, pageSize / 2, useCacheIfAvailable);

    // トークリストを連結
    const res = res1.concat(res2);
    await updateTalks(planId, res);
    // 追加のトークが存在するかどうか (論理削除分は無視)
    hasPrevTalk.value = res.filter(t => !t.deleteType).length > 0;
    hasNextTalk.value = res.filter(t => !t.deleteType).length > 0;

    // トーク情報を付与する
    setTalkInfoFromIdList(
      res.map((item: Talk) => {
        return item.talkId;
      }),
    );
  };

  // 既読取得
  const getLastRead = async (userId: string, talkRoomId: string) => {
    return await repository.getLastRead(userId, talkRoomId);
  };

  // 既読設定
  const setLastRead = async (userId: string, talkRoomId: string, talkId: string) => {
    const result = await repository.setLastRead(userId, talkRoomId, talkId);
    // 未読から既読へ変更された場合、内部データも既読にする
    if (result) {
      setUnreadFlag(talkRoomId, false);
    }
  };

  const sendTalk = async (
    talkRoomId: string,
    message: string,
    mentionUserIds: string[],
    replyTalkId: string | undefined,
  ) => {
    // 返信元が現在のトークリストに存在しない場合はエラー（別チャンネルのトークの可能性あり）
    // https://fincs.backlog.com/view/FINCS-2976#comment-458962486
    if (replyTalkId && !talks.value.some(item => item.talkId === replyTalkId)) {
      throw new Error(`sendTalk: replyTalkId ${replyTalkId} not found in room ${talkRoomId}`);
    }
    await repository.sendTalk(talkRoomId, message, mentionUserIds, replyTalkId);
  };

  const sendFileWithoutRoom = async (file: File, note: string) => {
    return await repository.sendFileWithoutRoom(file, note);
  };

  const sendFile = async (
    talkRoomId: string,
    file: File,
    note: string,
    mentionUserIds: string[],
    replyTalkId: string | undefined,
  ) => {
    // 返信元が現在のトークリストに存在しない場合はエラー（別チャンネルのトークの可能性あり）
    // https://fincs.backlog.com/view/FINCS-2976#comment-458962486
    if (replyTalkId && !talks.value.some(item => item.talkId === replyTalkId)) {
      throw new Error(`sendFile: replyTalkId ${replyTalkId} not found in room ${talkRoomId}`);
    }
    await repository.sendFile(talkRoomId, file, note, mentionUserIds, replyTalkId);
  };

  const editTalk = async (talkId: string, text: string, mentionUserIds: string[]) => {
    await repository.editTalk(talkId, text, mentionUserIds);
  };

  const deleteTalk = async (talkId: string) => {
    await repository.deleteTalk(talkId);
  };

  const sendGood = async (planId: string, talkRoomId: string, talkId: string, userId: string) => {
    await repository.sendGood(planId, talkRoomId, talkId, userId);
  };

  const deleteGood = async (planId: string, talkRoomId: string, talkId: string, userId: string) => {
    await repository.deleteGood(planId, talkRoomId, talkId, userId);
  };

  // トークのコンテンツURLが期限切れであれば再取得する処理
  const checkContentsUrl = async (talk: Talk): Promise<string | undefined> => {
    try {
      const result = CheckS3Expire(talk.contentsUrl);
      if (result) {
        return talk.contentsUrl;
      }
      // 無効なURLな場合はURLを再取得する
      const res = await repository.getTalkFromApi(selectedTalkRoomId.value, talk.talkId);
      if (res) {
        talk.contentsUrl = res.contents;
        return res.contents;
      }
    } catch (error: any) {
      sentryHandleException(error);
    }
    return undefined;
  };

  // キーを使ってコンテンツURLを取得する
  const getContentsUrl = async (key: string): Promise<{ statusCode: number; presignedUrl: string }> => {
    return await repository.getContentsUrl(key);
  };

  // 以下private

  const addTalkRoomCallback = (observeTalkRoom: { id: string; latesteTalkID: number }) => {
    // 最初にデータを入れる
    observeTalkRooms.value.push(observeTalkRoom);
  };

  const changeTalkRoomCallback = async (observeTalkRoom: { id: string; latesteTalkID: number }) => {
    const observeRoom = observeTalkRooms.value.find(item => item.id === observeTalkRoom.id);
    if (!observeRoom) {
      return;
    }
    if (!loginStore.isLogin()) {
      // 未ログイン時は既読管理しない
      return;
    }
    if (observeRoom.latesteTalkID !== observeTalkRoom.latesteTalkID) {
      // latesteTalkIDに違いがあったら未読フラグを調整
      const hasIncreased = observeRoom.latesteTalkID < observeTalkRoom.latesteTalkID;
      observeRoom.latesteTalkID = observeTalkRoom.latesteTalkID;
      if (observeRoom.id !== selectedTalkRoomId.value) {
        if (hasIncreased) {
          // メッセージ追加であれば未読に設定
          setUnreadFlag(observeRoom.id, true);
        } else {
          // メッセージ削除であれば既読トークIDをチェックし、それより大きい場合に未読に設定
          const lastRead = await repository.getLastRead(loginStore.loginInfo!.userId, observeRoom.id.toString());
          setUnreadFlag(observeRoom.id, !lastRead || lastRead < observeRoom.latesteTalkID);
        }
      }
    }
  };

  const addCallback = async (talk: Talk) => {
    if (!talkRoomSetDone.value) return;

    // 検索結果移動や返信元へのジャンプ後など、最新メッセージを保持していない場合は追加しない
    // (でないと途中に挿入され不正な状態となってしまう)
    if (hasNextTalk.value) {
      return;
    }

    // トークリストにpushする
    await addOneTalk(selectedPlanId.value, talk);
    // トーク情報を付与する。全部awaitで待つと遅いので並列処理とする
    setTalkInfoFromIdList([talk.talkId]);
  };

  const changeCallback = async (talk: Talk) => {
    if (!talkRoomSetDone.value) return;
    const oldIndex = talks.value.findIndex(item => item.talkId === talk.talkId);
    if (oldIndex !== -1) {
      // トークリストの中身を入れ替える
      await updateOneTalk(selectedPlanId.value, talk);
      // トーク情報を付与する。全部awaitで待つと遅いので並列処理とする
      setTalkInfoFromIdList([talk.talkId]);
    }
    // 返信元に変更があった場合
    const oldReplyIndex = talks.value.findIndex(item => item.replyTalkId === talk.talkId);
    if (oldReplyIndex !== -1) {
      // 返信元トークを更新 (OGPや返信は紐づかないので、素のデータのみ設定)
      setReplyTalk(talks.value[oldReplyIndex], talk);
    }
  };

  const addUsersCallback = (user: TalkRoomUser) => {
    if (!talkRoomSetDone.value) return;
    talkRoomUsers.value[user.userId] = user;
    setUpdateTalkFlag();
  };

  const changeUsersCallback = (user: TalkRoomUser) => {
    if (!talkRoomSetDone.value) return;
    talkRoomUsers.value[user.userId] = user;
    setUpdateTalkFlag();
  };

  // talkにいいね情報、OGP情報を付与する
  const setTalkInfoFromIdList = (talkIdList: string[]) => {
    // 返信情報を付与する
    setReplyInfoFromIdList(talkIdList);
    // OGP情報を付与する
    setTalkOGPInfoFromIdList(talkIdList);
    // ブックマーク情報を付与する（非同期）
    setBookmarkInfoFromIdList(talkIdList);

    setUpdateTalkFlag();
  };

  // talkにOGP情報を付与する
  const setTalkOGPInfoFromIdList = (talkIdList: string[]) => {
    try {
      let updated = false;
      for (const talkId of talkIdList) {
        const talk = talks.value.find(item => item.talkId === talkId);
        if (talk) {
          if (setTalkOGPInfo(talk)) {
            updated = true;
          }
        }
      }
      if (updated) {
        setUpdateTalkFlag();
      }
    } catch (error: any) {
      sentryHandleException(error);
    }
  };

  // talkに返信情報を付与する
  const setReplyInfoFromIdList = (talkIdList: string[]) => {
    try {
      let updated = false;
      for (const talkId of talkIdList) {
        const talk = talks.value.find(item => item.talkId === talkId);
        if (talk) {
          if (setReplyInfo(talk)) {
            updated = true;
          }
        }
      }
      if (updated) {
        setUpdateTalkFlag();
      }
    } catch (error: any) {
      sentryHandleException(error);
    }
  };

  // talkにOGP情報を付与する (talk単位)
  // 即時取得できたら true、取得不要または非同期取得になる場合は false を返す
  const setTalkOGPInfo = (talk: Talk): boolean => {
    // テキストメッセージ以外は何もしない（対象外）
    if (talk.messageType !== "text") {
      return false;
    }

    // トーク内のURLを抽出
    const regexpUrl = /(https?|ftp):\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g; // eslint-disable-line
    const urlAllMatchesTmp = talk.contents.match(regexpUrl);

    // URLなしの場合は何もしない（設定不要）
    if (!urlAllMatchesTmp) {
      return false;
    }

    // URL の重複を除去し、最初の 3 件のみとする
    // https://arkad-co.slack.com/archives/C06P8P9L95G/p1727078469367379?thread_ts=1727069923.243799&cid=C06P8P9L95G
    const urlAllMatches = _.uniq(urlAllMatchesTmp).slice(0, 3);

    if (USE_CACHE_FOR_ASYNC_DATA) {
      // まずキャッシュから探す
      const ogpList = talkOgpLists.value[talk.talkId];
      if (ogpList) {
        talk.ogpList = ogpList;
        return true;
      }
    }

    // キャッシュになければ非同期で取得
    // 取得中はスクロール待機判定できるようにフラグ立てる
    talk.ogpLoading = true;
    const getOgpListAsync = async () => {
      try {
        let countFromBrowserCache = 0;
        for (let i = 0; i < urlAllMatches.length; i++) {
          if (await setTalkOGPInfoByUrl(talk, urlAllMatches[i])) {
            countFromBrowserCache++;
          }
        }
        if (countFromBrowserCache < urlAllMatches.length) {
          // 一部でもブラウザキャッシュから取得できなかった場合は debounce 後に取得されるので、
          // その分 ogpLoading を延長してスクロールを継続させる
          await new Promise(resolve => setTimeout(resolve, 200));
        }
      } finally {
        talk.ogpLoading = false;
        setUpdateTalkFlag();
      }
    };
    getOgpListAsync();

    // 非同期処理されるのでここでは false を返す
    return false;
  };

  // talkにOGP情報を付与する (talk内の各url単位)
  // ブラウザキャッシュから取得できたら true、サーバ取得になる場合は false を返す
  const setTalkOGPInfoByUrl = async (talk: Talk, url: string) => {
    await delayAsyncForDebug();

    // まず API から OGP を取得する関数を定義
    const getAndSetOgpToTalk = async (talk: Talk, url: string) => {
      try {
        const ogpLists = await ogpRepository.getOgp(url);
        // OGP取れなかったら作らない
        if (ogpLists?.length) {
          const ogp = new TalkOgp("", "", "", url);
          ogp.title = _.find(ogpLists, ["property", "og:title"])?.content;
          ogp.description = _.find(ogpLists, ["property", "og:description"])?.content;
          ogp.image = _.find(ogpLists, ["property", "og:image"])?.content;
          talk.ogpList.push(ogp);
          talkOgpLists.value[talk.talkId] = talk.ogpList; // キャッシュ登録
          setUpdateTalkFlag(); // 表示更新
        }
        // ブラウザキャッシュ済みであることを記録
        ogpRequestCached.set(url, true);
      } catch (_e) {
        // ogp取得APIエラー
        // ogpRepository.getOgp 配下で sentry にログを送っているのでここでは何もしない
      }
    };

    if (USE_CACHE_FOR_ASYNC_DATA) {
      const cachedOgp = ogpRequestCached.get(url);
      if (cachedOgp) {
        // OGP URL がブラウザキャッシュにあれば即時取得
        await getAndSetOgpToTalk(talk, url);
        return true;
      }
    }

    // OGP URL がブラウザキャッシュになければデバウンスでバースト抑止
    // 200msタイマーとしたのは、OGP取得リクエストが概ね100ms前後なので余裕を持ちつつ、表示が遅くなりすぎない値として選定
    const key = `${talk.talkId}-${url}`;
    let ogpRequestDebounce = ogpRequestDebounces.get(key);
    if (!ogpRequestDebounce) {
      ogpRequestDebounce = _.debounce(() => {
        getAndSetOgpToTalk(talk, url);
      }, 200);
      ogpRequestDebounces.set(key, ogpRequestDebounce);
    }
    ogpRequestDebounce();

    // debounce で非同期処理されるのでここでは false を返す
    return false;
  };

  // talkに返信情報を付与する
  // 即時取得できたら true、取得不要または非同期取得になる場合は false を返す
  const setReplyInfo = (talk: Talk): boolean => {
    // 返信元がない場合は何もしない（対象外）
    if (!talk.replyTalkId) {
      return false;
    }

    // 返信元設定済みの場合は何もしない（設定不要）
    if (talk.replyTalk) {
      return false;
    }

    // 返信情報を loading で初期化。後続の処理で設定されるまではこれを表示して初期レイアウト調整
    setReplyTalk(talk, newLoadingTalk(talk.replyTalkId!));

    if (USE_CACHE_FOR_ASYNC_DATA) {
      // まずキャッシュから探す
      let replyTalk = replyTalks.value.find(item => item.talkId === talk.replyTalkId);
      if (replyTalk) {
        setReplyTalk(talk, replyTalk);
        return true;
      }

      // 次に自分のトークリストから探す
      replyTalk = talks.value.find(item => item.talkId === talk.replyTalkId);
      if (replyTalk) {
        replyTalks.value.push(replyTalk); // キャッシュ登録
        setReplyTalk(talk, replyTalk);
        return true;
      }
    }

    // 上記いずれもなければFirestoreから取得しキャッシュに追加する
    // デバウンスでバースト抑止
    // 200msタイマーとしたのは、トーク取得リクエストが概ね100ms前後なので余裕を持ちつつ、表示が遅くなりすぎない値として選定
    const key = talk.talkId;
    let replyRequestDebounce = replyRequestDebounces.get(key);
    if (!replyRequestDebounce) {
      replyRequestDebounce = _.debounce(async () => {
        await delayAsyncForDebug();
        const res = await repository.getTalk(selectedPlanId.value, selectedTalkRoomId.value, talk.replyTalkId!);
        if (res) {
          if (!replyTalks.value.some(item => item.talkId === talk.replyTalkId)) {
            replyTalks.value.push(res); // キャッシュ登録。非同期に取るので重複チェックもしておく
          }
          setReplyTalk(talk, res);
          setUpdateTalkFlag(); // このルートだけ非同期で処理されるのでここで update フラグを立てる
        }
      }, 200);
      replyRequestDebounces.set(key, replyRequestDebounce);
    }
    replyRequestDebounce();

    // debounce で非同期処理されるのでここでは false を返す
    return false;
  };

  const setReplyTalk = (talk: Talk, replyTalk: Talk) => {
    if (replyTalk.userId) {
      // 返信元ユーザーが未設定の場合に非同期で取得
      fetchTalkRoomUser(replyTalk);
    }

    // 返信元トークを設定
    talk.replyTalk = replyTalk;
  };

  const setBookmarkInfoFromIdList = async (targetTalkIds: string[]) => {
    if (!loginStore.loginInfo.userId) {
      // 未ログインではブックマーク情報を取得できないので何もしない
      return;
    }

    // 現在のルームにおけるブックマーク情報を取得、targetTalks と合致するものに絞り込む
    const keys = await repository.getTalkBookmarkKeysByRoomId(
      selectedPlanId.value,
      loginStore.loginInfo.userId,
      selectedTalkRoomId.value,
    );
    const filteredKeys = keys.filter(k => targetTalkIds.includes(k.talkId));

    // 読み込み済みトークにブックマーク情報を付与して表示更新
    let needsUpdate = false;
    for (const key of filteredKeys) {
      const talk = talks.value.find(t => t.talkId === key.talkId);
      if (talk && !talk.bookmarked) {
        talk.bookmarked = true;
        needsUpdate = true;
      }
    }
    if (needsUpdate) {
      setUpdateTalkFlag();
    }
  };

  // トークルーム数が最大値に達しているかどうか
  const isMaxGroupTalkRooms = () => {
    return talkRooms.value.filter(room => !room.isArchive && !room.isDirectMessage).length >= TALK_ROOM_GROUP_MAX;
  };

  // DMトークルーム数が最大値に達しているかどうか
  const isMaxDMTalkRooms = () => {
    return talkRooms.value.filter(room => room.isDirectMessage && !room.isArchive).length >= TALK_ROOM_DM_MAX;
  };

  return {
    talkRooms,
    talkRoomsForSetting,
    talks,
    talkRoomSetDone,
    hasPrevTalk,
    hasNextTalk,
    loading,
    updating,
    updateTalk,
    initRenderingFinished,
    canSearch,
    searchOpen,
    searchText,
    replyTalkToEdit,
    talkRoomUsers,
    userGoodList,
    startTalkRoomsObserve,
    stopTalkRoomsObserve,
    startUsersObserve,
    stopUsersObserve,
    startTalksObserve,
    stopTalksObserve,
    getTalkRooms,
    getTalkRoomsForSetting,
    getGoodUsers,
    updateTalkRoomUsers,
    getMentionUsers,
    searchTalkCountsInPlan,
    searchTalkIds,
    hasTalk,
    getTalksBefore,
    getTalksAfter,
    getTalksAt,
    getLastRead,
    setLastRead,
    sendTalk,
    setTalkOGPInfo,
    getContentsUrl,
    sendFileWithoutRoom,
    sendFile,
    initPlan,
    initTalkRoom,
    editTalk,
    deleteTalk,
    sendGood,
    deleteGood,
    clearUpdateTalkFlag,
    updateTalkValue,
    checkContentsUrl,

    startUserGoodsObserve,
    stopUserGoodsObserve,
    isMaxGroupTalkRooms,
    isMaxDMTalkRooms,

    // 以下は VisibleForTesting
    fetchTalkRoomUser,
    addTalkRoomCallback,
    changeTalkRoomCallback,
  };
});
