import { serverTimestamp } from "firebase/firestore";
import { TalkOgp } from "./TalkOgp";

// メッセージタイプ
export type MessageType = "text" | "date" | "pdf" | "image" | "loading";

export enum DeleteType {
  none = 0,
  delete = 1,
  systemDelete = 9,
}

// トークデータ
export class Talk {
  public talkId: string;
  public talkRoomId: string; // firestoreには無いデータ。検索結果などビュー用に設定される
  public messageType: MessageType;
  public contents: string;
  public contentsUrl: string;
  public fileName: string;
  public fileKey: string | undefined; // firestoreには無いデータ。一斉配信の一部処理でファイルのキーとして使う
  public thumbnailImage: string; // サムネイル画像 (旧 base64)
  public thumbnailImageUrl: string; // サムネイル画像 (署名付きURL)
  public userId: string;
  public deleteType: DeleteType;
  public postDateTime: Date | null;
  public updateDateTime: Date | null;
  public note: string | undefined;
  public mentionUserIds: string[] | undefined;
  public replyTalkId: string | undefined;
  public goodCount: number;

  public bookmarked: boolean; // firestoreの別コレクションから取得したデータ

  // firestoreには無いデータ
  public ogpLoading: boolean;
  public ogpList: TalkOgp[];
  // eslint-disable-next-line no-use-before-define
  public replyTalk: Talk | undefined;
  public date: string | undefined; // 日付表示用

  constructor(talkId: string, options: any = {}) {
    this.talkId = talkId;
    this.talkRoomId = options?.talkRoomId ?? "";
    this.messageType = options?.messageType ?? "text";
    this.contents = options?.contents ?? "";
    this.contentsUrl = options?.contentsUrl ?? "";
    this.fileName = options?.fileName ?? "";
    this.fileKey = options?.fileKey ?? undefined;
    this.thumbnailImage = options?.thumbnailImage ?? "";
    this.thumbnailImageUrl = options?.thumbnailImageUrl ?? "";
    this.userId = options?.userId ?? "";
    this.deleteType = options?.deleteType ?? 0;
    this.postDateTime = options?.postDateTime ?? new Date();
    this.updateDateTime = options?.updateDateTime ?? null;
    this.note = options?.note ?? undefined;
    this.mentionUserIds = options?.mentionUserIds ?? undefined;
    this.replyTalkId = options?.replyTalkId ?? undefined;
    this.goodCount = options?.goodCount ?? 0;
    this.bookmarked = !!options?.bookmarked;
    this.ogpLoading = !!options?.ogpLoading;
    this.ogpList = options?.ogpList ?? [];
    this.replyTalk = options?.replyTalk ?? undefined;
    this.date = options?.date ?? undefined;
  }
}

// Firestore data converter
export const TalkConverter = {
  toFirestore: (item: any) => {
    return {
      TalkId: item.talkId,
      Type: item.messageType,
      Message: item.contents,
      contentsUrl: item.contentsUrl,
      fileName: item.fileName,
      thumbnailImage: item.thumbnailImage,
      thumbnailImageUrl: item.thumbnailImageUrl,
      UserId: item.userId,
      deleteType: item.deleteType,
      InsertDate: item.postDateTime ?? serverTimestamp(),
      UpdateDate: item.updateDateTime ?? serverTimestamp(),
      note: item.note,
      mentionUserIds: item.mentionUserIds,
      replyTalkId: item.replyTalkId,
      GoodCount: item.goodCount,
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new Talk(String(data.TalkId), {
      messageType: data.Type,
      contents: data.Message,
      contentsUrl: data.contentsUrl,
      fileName: data.fileName,
      thumbnailImage: data.thumbnailImage,
      thumbnailImageUrl: data.thumbnailImageUrl,
      userId: data.UserId,
      deleteType: data.deleteType,
      postDateTime: data.InsertDate?.toDate(),
      updateDateTime: data.UpdateDate?.toDate(),
      note: data.note || undefined,
      mentionUserIds: data.mentionUserIds || undefined,
      replyTalkId: data.replyTalkId ? String(data.replyTalkId) : undefined,
      goodCount: data.GoodCount,
    });
  },
};

export const newLoadingTalk = (talkId: string) =>
  new Talk(talkId, {
    messageType: "loading",
    postDateTime: new Date(0),
  });
