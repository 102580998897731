<script setup lang="ts">
  const layoutCustomProps = useAttrs();

  const heading = computed(() => {
    return layoutCustomProps.heading;
  });
  const isPersonalInfo = layoutCustomProps.ispersonalinfo ? layoutCustomProps.ispersonalinfo : false;
  const canBack = layoutCustomProps.back ?? false;
  const narrow = layoutCustomProps.narrow ?? "yes";
  const columns = layoutCustomProps.columns;

  const bgColor = computed(() => {
    return layoutCustomProps.color ?? "monochrome-04";
  });

  const onClickBack = () => {
    useRouter().back();
  };

  // レスポンシブ対応
  const isSp = isSpDisplayRef();
  const bodyColumns = computed<number>(() => Number(columns) || (isSp.value ? 11 : 10));
  const pageTitleFontSize = computed<string>(() => (isSp.value ? "20px" : "22px"));
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="mx-auto" style="width: 90%">
        <v-icon v-if="canBack" data-testid="back-button" size="40px" class="back" @click="onClickBack"
          >$mdiChevronLeft</v-icon
        >
        <div class="d-flex flex-row justify-center">
          <NuxtLink to="/">
            <AtomsFincsLogoSvg height="30" class="mt-8" />
          </NuxtLink>
        </div>
      </div>
      <div class="mx-auto" width="90%">
        <slot name="header"></slot>
      </div>
      <template v-if="isPersonalInfo">
        <!-- 画面見出し -->
        <div :class="[isSp ? 'mb-4 mt-6' : 'mb-8 mt-12', 'heading']">{{ heading }}</div>
        <!-- ページ本体 -->
        <slot></slot>
      </template>
      <template v-else>
        <v-sheet width="90%" :color="bgColor" class="mx-auto my-8" :class="{ container: narrow == 'yes' }">
          <v-row justify="center" class="my-4">
            <v-col :cols="bodyColumns" align="center">
              <!-- 画面見出し -->
              <div :class="[isSp ? 'mt-0 mb-4' : 'my-4', 'heading']">{{ heading }}</div>
              <!-- ページ本体 -->
              <slot></slot>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
      <div class="mx-auto" style="width: 90%">
        <slot name="bottom-content" />
      </div>
    </NuxtLayout>
  </div>
</template>

<style lang="scss" scoped>
  @media (min-width: 1280px) {
    .container {
      max-width: 1280px !important;
    }
  }

  @media (min-width: 960px) {
    .container {
      max-width: 900px !important;
    }
  }
  .back {
    position: absolute;
    top: 48px;
  }
  .heading {
    font-size: v-bind(pageTitleFontSize);
    font-weight: 700;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    text-align: center;
  }
</style>
