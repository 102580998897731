<script setup lang="ts">
  import { storeToRefs } from "pinia";
  import { PlanInfo } from "~/entities/PlanInfo";
  import {
    type NotificationSettingContentsType,
    NotificationSettingItem,
    NotificationSettings,
  } from "~/entities/NotificationSetting";
  import { useSnackBarStore } from "~/stores/SnackBarStore";
  import { useLoginStore } from "~/stores/LoginStore";
  import { useTalkRoomStore } from "~/stores/TalkRoomStore";
  import { useNotificationSettingStore } from "~/stores/NotificationSettingStore";
  import { useLineLinkStore } from "~/stores/LineLinkStore";

  interface Props {
    planInfo: PlanInfo;
    isOpen: boolean;
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため
  }
  const props = defineProps<Props>();

  interface Emits {
    (event: "update:isOpen", isOpen: boolean): void;
  }
  const emit = defineEmits<Emits>();
  const computedIsOpen = computed<boolean>({
    get: () => props.isOpen,
    set: value => {
      emit("update:isOpen", value);
    },
  });

  const planId = props.planInfo.id.toString();

  const tab = ref("webpush");
  const loading = ref(true);

  const snackBarStore = useSnackBarStore();

  const lineLinkStore = useLineLinkStore();

  const loginStore = useLoginStore();
  const { loginInfo } = storeToRefs(loginStore);
  const isOwner = computed(() => {
    return getOwnerIds(props.planInfo).includes(loginInfo.value?.userId);
  });
  const isMember = computed(() => {
    return (
      props.planInfo.contractStatus.contractStatusCode === "active" ||
      props.planInfo.contractStatus.contractStatusCode === "cancellation"
    );
  });

  // 通知登録有無確認
  const webpushEnabled = computed(() => {
    return props.planInfo.isWebPushLinked;
  });
  const lineEnabled = computed(() => {
    return props.planInfo.isLineLinked;
  });

  // トークルーム情報取得
  const talkRoomStore = useTalkRoomStore();
  const { talkRoomsForSetting } = storeToRefs(talkRoomStore);
  // 対象講座の講師・会員のいずれでもない場合は無料のトークルームのみ表示
  // また非公開・アーカイブ済みのトークルームは表示しない
  const filteredTalkRooms = computed(() =>
    talkRoomsForSetting.value.filter(
      talkRoom => (isOwner.value || isMember.value || talkRoom.isFree) && talkRoom.published && !talkRoom.isArchive,
    ),
  );
  const groupTalkRooms = computed(() => filteredTalkRooms.value.filter(talkRoom => !talkRoom.isDirectMessage));

  // 通知設定取得
  const notificationSettingStore = useNotificationSettingStore();
  const { notificationSettings } = storeToRefs(notificationSettingStore);

  // 通知設定表示 / コンテンツ
  const contentIconImgPaths = [
    "assets/images/icon/menu/live.svg",
    "assets/images/icon/menu/movie.svg",
    "assets/images/icon/menu/document.svg",
  ];
  const contentInactiveIconImgPaths = [
    "assets/images/icon/menu/live-inactive.svg",
    "assets/images/icon/menu/movie-inactive.svg",
    "assets/images/icon/menu/document-inactive.svg",
  ];
  const webpushContentIconImgPaths = computed(() => {
    return webpushEnabled.value ? contentIconImgPaths : contentInactiveIconImgPaths;
  });
  const lineContentIconImgPaths = computed(() => {
    return lineEnabled.value ? contentIconImgPaths : contentInactiveIconImgPaths;
  });
  const contentLabels = ["ライブ配信の通知", "動画投稿の通知", "資料投稿の通知"];
  const contentKeys: NotificationSettingContentsType[] = ["live_movie", "movie", "document"];
  const webpushContentValues = ref<NotificationSettingContentsType[]>([]);
  const lineContentValues = ref<NotificationSettingContentsType[]>([]);

  // 通知設定表示 / ダイレクトメッセージ
  const dMTalkIconImgPaths = ["assets/images/icon/menu/plan_talk_dm.svg"];
  const dMTalkInactiveIconImgPaths = ["assets/images/icon/menu/plan_talk_dm-inactive.svg"];
  const webpushDMTalkIconImgPaths = computed(() => {
    return webpushEnabled.value ? dMTalkIconImgPaths : dMTalkInactiveIconImgPaths;
  });
  const lineDMTalkIconImgPaths = computed(() => {
    return lineEnabled.value ? dMTalkIconImgPaths : dMTalkInactiveIconImgPaths;
  });
  const dMTalkLabels = ["ダイレクトメッセージの通知"];
  const dMTalkKeys: string[] = ["dm_talk_rooms"];
  const webpushDMTalkValues = ref<string[]>([]);
  const lineDMTalkValues = ref<string[]>([]);

  // 通知設定表示 / トークメンション
  const talkMentionIconImgPaths = ["assets/images/icon/menu/plan_talk_mention.svg"];
  const talkMentionInactiveIconImgPaths = ["assets/images/icon/menu/plan_talk_mention-inactive.svg"];
  const webpushTalkMentionIconImgPaths = computed(() => {
    return webpushEnabled.value ? talkMentionIconImgPaths : talkMentionInactiveIconImgPaths;
  });
  const lineTalkMentionIconImgPaths = computed(() => {
    return lineEnabled.value ? talkMentionIconImgPaths : talkMentionInactiveIconImgPaths;
  });
  const talkMentionLabels = ["自分にメンションがあった際の通知"];
  const talkMentionKeys: string[] = ["talk_mentions"];
  const webpushTalkMentionValues = ref<string[]>([]);
  const lineTalkMentionValues = ref<string[]>([]);

  // 通知設定表示 / トークチャンネル
  const webpushTalkRoomIconImgPaths = computed<string[]>(() => {
    return Array(groupTalkRooms.value.length).fill(
      `assets/images/icon/menu/plan_talk${webpushEnabled.value ? "" : "-inactive"}.svg`,
    );
  });
  const lineTalkRoomIconImgPaths = computed<string[]>(() => {
    return Array(groupTalkRooms.value.length).fill(
      `assets/images/icon/menu/plan_talk${lineEnabled.value ? "" : "-inactive"}.svg`,
    );
  });
  const talkRoomLabels = ref<string[]>([]);
  const talkRoomKeys = ref<string[]>([]);
  const webpushTalkRoomValues = ref<string[]>([]);
  const lineTalkRoomValues = ref<string[]>([]);

  const getContentValues = (settings: NotificationSettingItem[]) => {
    return contentKeys.filter(contentsType =>
      settings.find(setting => setting.enable && setting.contentsType === contentsType),
    );
  };

  const getDMTalkRoomValues = (settings: NotificationSettingItem[]) => {
    return settings.some(
      setting =>
        setting.enable &&
        setting.contentsType === "talk_room" &&
        setting.contentsSubType === "talk_dm" &&
        setting.roomId == null,
    )
      ? ["dm_talk_rooms"]
      : [];
  };

  const getTalkMentionValues = (settings: NotificationSettingItem[]) => {
    return settings.some(
      setting => setting.enable && setting.contentsType === "talk_room" && setting.contentsSubType === "talk_mention",
    )
      ? ["talk_mentions"]
      : [];
  };

  const getGroupTalkRoomValues = (talkRoomIds: string[], settings: NotificationSettingItem[]) => {
    return talkRoomIds.filter(talkRoomId =>
      settings.find(
        setting => setting.enable && setting.contentsType === "talk_room" && setting.roomId?.toString() === talkRoomId,
      ),
    );
  };

  // 現在の通知設定を表示反映する
  const syncNotificationSettings = () => {
    // ライブ・動画・資料の通知設定を反映
    webpushContentValues.value = getContentValues(notificationSettings.value!.webpushSettings);
    lineContentValues.value = getContentValues(notificationSettings.value!.lineSettings);

    // ダイレクトメッセージ全体の通知設定を反映
    webpushDMTalkValues.value = getDMTalkRoomValues(notificationSettings.value!.webpushSettings);
    lineDMTalkValues.value = getDMTalkRoomValues(notificationSettings.value!.lineSettings);

    // トークメンション全体の通知設定を反映
    webpushTalkMentionValues.value = getTalkMentionValues(notificationSettings.value!.webpushSettings);
    lineTalkMentionValues.value = getTalkMentionValues(notificationSettings.value!.lineSettings);

    // 各トークチャンネルの通知設定を反映
    const groupTalkRoomIds = groupTalkRooms.value.map(talkRoom => talkRoom.talkRoomId);
    talkRoomLabels.value = groupTalkRooms.value.map(talkRoom => talkRoom.roomName + "の通知");
    talkRoomKeys.value = groupTalkRoomIds;
    webpushTalkRoomValues.value = getGroupTalkRoomValues(groupTalkRoomIds, notificationSettings.value!.webpushSettings);
    lineTalkRoomValues.value = getGroupTalkRoomValues(groupTalkRoomIds, notificationSettings.value!.lineSettings);
  };

  // 通知設定を保存
  const onSave = async () => {
    const webpushSettings = webpushContentValues.value
      .map(contentsType => {
        return NotificationSettingItem.forPutContentsSetting("webpush", contentsType);
      })
      .concat(
        webpushDMTalkValues.value.map(_ => {
          return NotificationSettingItem.forPutTalkRoomSetting("webpush", "talk_dm", null);
        }),
      )
      .concat(
        webpushTalkMentionValues.value.map(_ => {
          return NotificationSettingItem.forPutTalkRoomSetting("webpush", "talk_mention", null);
        }),
      )
      .concat(
        webpushTalkRoomValues.value.map(roomId => {
          return NotificationSettingItem.forPutTalkRoomSetting("webpush", null, Number(roomId));
        }),
      );
    const lineSettings = lineContentValues.value
      .map(contentsType => {
        return NotificationSettingItem.forPutContentsSetting("line", contentsType);
      })
      .concat(
        lineDMTalkValues.value.map(_ => {
          return NotificationSettingItem.forPutTalkRoomSetting("line", "talk_dm", null);
        }),
      )
      .concat(
        lineTalkMentionValues.value.map(_ => {
          return NotificationSettingItem.forPutTalkRoomSetting("line", "talk_mention", null);
        }),
      )
      .concat(
        lineTalkRoomValues.value.map(roomId => {
          return NotificationSettingItem.forPutTalkRoomSetting("line", null, Number(roomId));
        }),
      );
    if (webpushSettings.length === 0 && lineSettings.length === 0) {
      snackBarStore.setError("通知を1つ以上選択してください。通知不要な場合、通知登録を解除してください");
      return;
    }
    const settings = new NotificationSettings(webpushSettings, lineSettings);
    try {
      await notificationSettingStore.putSettings(planId, settings);

      // put 後に再取得して表示状態を永続 (これがないとダイアログクローズ時に元に戻る)
      await notificationSettingStore.fetchSettings(planId);

      snackBarStore.set("通知設定を保存しました");
      computedIsOpen.value = false;
    } catch (error: any) {
      sentryErrorLog("通知設定保存失敗: " + JSON.stringify(error));
      snackBarStore.setError("通知設定の保存に失敗しました");
    }
  };

  // レスポンシブ対応
  const isSp = isSpDisplayRef();
  const webpushTabClass = computed(() =>
    isSp.value
      ? (tab.value === "webpush" ? "active-tab-sp" : "inactive-tab-sp") + " d-flex flex-row"
      : tab.value === "webpush"
        ? "active-tab"
        : "inactive-tab",
  );
  const lineTabClass = computed(() =>
    isSp.value
      ? tab.value === "line"
        ? "active-tab-sp"
        : "inactive-tab-sp"
      : tab.value === "line"
        ? "active-tab"
        : "inactive-tab",
  );

  const isPwaEnabled = computed(() => useRuntimeConfig().public.ENABLE_PWA === "true");
  const canMention = computed(featureTalkMention);

  const fetch = async () => {
    loading.value = true;
    try {
      // LineBtnでも取得するが、LineBtnのマウントの前にデータがないと表示がガクッとズレるのでここで取得する
      if (loginStore.isLogin()) {
        await lineLinkStore.fetch();
      }

      await talkRoomStore.getTalkRoomsForSetting(planId);
      await notificationSettingStore.fetchSettings(planId);

      syncNotificationSettings();
    } finally {
      loading.value = false;
    }
  };

  onMounted(() => {
    fetch();
  });
</script>

<template>
  <v-dialog v-model="computedIsOpen" :width="isSp ? '90%' : '70%'" scrim="black" scrollable :attach="!!isTest">
    <v-card
      v-if="!loading"
      class="d-flex flex-column justify-center dialog-body"
      :class="[isSp ? 'pt-8 pb-4' : 'px-16 py-4']"
    >
      <div class="close-button" @click="computedIsOpen = false">✕</div>

      <v-card-text :class="isSp ? 'pa-2' : ''">
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab value="webpush" :class="webpushTabClass" data-testid="webpush-notification-tab">
            <div :class="isSp ? '' : 'd-flex'">
              <AtomsSvgIcon
                :width="20"
                :margin-x="2"
                :img-path="`assets/images/icon/${tab == 'webpush' ? 'push-on' : 'push-on-inactive'}.svg`"
                class="mt-2"
                :class="isSp ? 'mx-auto' : 'mx-2'"
              />
              <div>WEBプッシュ通知</div>
            </div>
          </v-tab>
          <v-tab value="line" :class="lineTabClass" data-testid="line-notification-tab">
            <div :class="isSp ? '' : 'd-flex'">
              <AtomsSvgIcon
                :width="20"
                :img-path="`assets/images/icon/${tab == 'line' ? 'line' : 'line-inactive'}.svg`"
                class="mt-1"
                :class="isSp ? 'mx-auto' : 'mx-2'"
              />
              <div>LINE通知</div>
            </div>
          </v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item value="webpush">
            <!-- PUSH通知ボタン -->
            <MoleculesPushNotificationButton
              v-if="isPwaEnabled"
              :plan-info="planInfo"
              class="my-8 d-flex justify-center"
            />

            <v-divider :thickness="2"></v-divider>
            <AtomsSettingSwitches
              v-model:values="webpushContentValues"
              :icon-img-paths="webpushContentIconImgPaths"
              :labels="contentLabels"
              :keys="contentKeys"
              :disabled="!webpushEnabled"
            />
            <AtomsSettingSwitches
              v-model:values="webpushDMTalkValues"
              :icon-img-paths="webpushDMTalkIconImgPaths"
              :labels="dMTalkLabels"
              :keys="dMTalkKeys"
              :disabled="!webpushEnabled"
            />
            <AtomsSettingSwitches
              v-if="canMention"
              v-model:values="webpushTalkMentionValues"
              :icon-img-paths="webpushTalkMentionIconImgPaths"
              :labels="talkMentionLabels"
              :keys="talkMentionKeys"
              :disabled="!webpushEnabled"
              data-testid="webpush-notification-talk-mention-switch"
            />
            <AtomsSettingSwitches
              v-model:values="webpushTalkRoomValues"
              :icon-img-paths="webpushTalkRoomIconImgPaths"
              :labels="talkRoomLabels"
              :keys="talkRoomKeys"
              :disabled="!webpushEnabled"
            />
          </v-window-item>

          <v-window-item value="line">
            <!-- LINE ボタン -->
            <MoleculesLineBtn :plan-info="planInfo" class="my-8 d-flex justify-center" />

            <v-divider :thickness="2"></v-divider>
            <AtomsSettingSwitches
              v-model:values="lineContentValues"
              :icon-img-paths="lineContentIconImgPaths"
              :labels="contentLabels"
              :keys="contentKeys"
              :disabled="!lineEnabled"
            />
            <AtomsSettingSwitches
              v-model:values="lineDMTalkValues"
              :icon-img-paths="lineDMTalkIconImgPaths"
              :labels="dMTalkLabels"
              :keys="dMTalkKeys"
              :disabled="!lineEnabled"
            />
            <AtomsSettingSwitches
              v-if="canMention"
              v-model:values="lineTalkMentionValues"
              :icon-img-paths="lineTalkMentionIconImgPaths"
              :labels="talkMentionLabels"
              :keys="talkMentionKeys"
              :disabled="!lineEnabled"
              data-testid="line-notification-talk-mention-switch"
            />
            <AtomsSettingSwitches
              v-model:values="lineTalkRoomValues"
              :icon-img-paths="lineTalkRoomIconImgPaths"
              :labels="talkRoomLabels"
              :keys="talkRoomKeys"
              :disabled="!lineEnabled"
            />
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions class="justify-center">
        <div class="d-flex justify-center">
          <AtomsButton
            class="my-4"
            :class="[isSp ? 'mx-4' : 'mx-8']"
            :width="isSp ? 120 : 200"
            color="white"
            style-bg-color="#AAAAAA"
            data-testid="notification-setting-cancel"
            @click="computedIsOpen = false"
          >
            <span class="text-dialog-button">キャンセル</span>
          </AtomsButton>
          <AtomsButton
            class="my-4"
            :class="[isSp ? 'mx-4' : 'mx-8']"
            :width="isSp ? 120 : 200"
            color="white"
            style-bg-color="#119CF6"
            :disabled="!webpushEnabled && !lineEnabled"
            data-testid="notification-setting-save"
            @click="onSave"
          >
            <span class="text-dialog-button">保存する</span>
          </AtomsButton>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .dialog-body {
    height: 100%;
    color: white;
    background-color: #222222;
  }
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .active-tab {
    color: white;
    border-bottom: 2px solid white;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.24px; /* アイコンと合わせるために個別 */
    letter-spacing: 0.06em;
  }
  .inactive-tab {
    color: #aaaaaa;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.24px; /* アイコンと合わせるために個別 */
  }
  .active-tab-sp {
    color: white;
    border-bottom: 2px solid white;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px; /* アイコンと合わせるために個別 */
    letter-spacing: 0.06em;
  }
  .inactive-tab-sp {
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px; /* アイコンと合わせるために個別 */
    letter-spacing: 0.06em;
  }
</style>
