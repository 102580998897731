// service workerからlocalStorageにアクセスすることはできないので、localforage(indexedDBのラッパー)を利用する。
import localforage from "localforage";

// localforageの初期化
const initLocalForage = () => {
  localforage.config({
    driver: localforage.INDEXEDDB,
    name: "fincs-db",
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    version: 1.0,
    storeName: "keyvaluepairs",
    description: "",
  });
};

export const getUserId = async (): Promise<string> => {
  initLocalForage();
  return (await localforage.getItem("userId")) ?? "";
};

export const setUserId = async (userId: string) => {
  initLocalForage();
  await localforage.setItem("userId", userId);
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    getUserId: () => Promise<string>;
    setUserId: (userId: string) => Promise<void>;
  }
}
