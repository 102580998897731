import type { Talk } from "./Talk";

// トークユーザーデータ（ブックマーク・メンション一覧表示用）
export class TalkUserData {
  constructor(
    public talk: Talk,
    public createdAt: Date,
    public hasUnread: boolean,
  ) {}
}

// トークユーザーデータキー（Firestore に保存。データをルーム・トークと紐づける）
export class TalkUserDataKeys {
  constructor(
    public talkId: string,
    public talkRoomId: string,
    public createdAt: Date,
  ) {}
}

// Firestore data converter
export const TalkUserDataKeysConverter = {
  toFirestore: (item: any) => {
    return {
      talkId: Number(item.talkId),
      talkRoomId: Number(item.talkRoomId),
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new TalkUserDataKeys(data.talkId.toString(), data.talkRoomId.toString(), data.createdAt.toDate());
  },
};
