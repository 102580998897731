<script setup lang="ts">
  import { storeToRefs } from "pinia";
  import { useNavigationStore } from "~/stores/NavigationStore";
  import { PLAN_TYPE_LABEL_LIST } from "~/consts/contentsConfig";

  const navigationStore = useNavigationStore();

  defineProps<{
    enableSelect: boolean; // 講座選択のセレクトボックスを表示するか
  }>();

  const { planTypeLabel } = storeToRefs(navigationStore);

  const planTypeLabelValue = computed<string>({
    get: () => planTypeLabel.value,
    set: value => {
      navigationStore.setPlanTypeByLabel(value);
      // クエリパラメータを変更
      navigationStore.changeQueryByPlanType();
    },
  });
</script>

<template>
  <div v-if="enableSelect" class="left-border">
    <v-select
      v-model="planTypeLabelValue"
      :items="PLAN_TYPE_LABEL_LIST"
      density="compact"
      variant="solo"
      label=""
      :hide-details="true"
    >
      <template #item="{ props: itemProps, item }">
        <v-list-item v-if="item.title !== planTypeLabelValue" v-bind="itemProps">
          <template #title>
            <div class="item-title">{{ item.title }}</div>
          </template>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<style lang="scss" scoped>
  @import "assets/media.scss";
  .left-border {
    position: relative;
    padding-left: 6px;
    margin-left: 0px;
    @include display_sp {
      padding-left: 2px;
      margin-left: 10px;
    }
  }
  .left-border::before {
    position: absolute; /*--positionを追加--*/
    top: 28%;
    left: 0;
    content: "";
    width: 1px; /*--縦線の太さ(幅)--*/
    height: 22px;
    background-color: rgb(var(--v-theme-monochrome-02));
  }
  :deep(.v-select__selection-text),
  .item-title {
    font-size: 18px !important;
    letter-spacing: 0.06em !important;
    font-weight: 700 !important;
    color: white;
    @include display_sp {
      font-size: 16px !important;
    }
  }
</style>
