<script setup lang="ts">
  import { useAppStore } from "~/stores/AppStore";

  const showDialog = ref(false);

  const clickReload = () => {
    showDialog.value = false;
    appStore.executeUpdate();
  };

  // AppStore の初期化
  const appStore = useAppStore();
  await appStore.init();

  // アプリ更新があればダイアログ表示
  watch(
    () => appStore.needsUpdate,
    () => {
      if (appStore.needsUpdate) {
        showDialog.value = true;

        // キャンセル選択後、再度チェック周期が来た際に改めてダイアログ表示
        appStore.needsUpdate = false;
      }
    },
  );
</script>

<template>
  <v-dialog v-model="showDialog" scrim="black" persistent :attach="true">
    <v-sheet class="app-update-dialog py-8 px-10 rounded" color="monochrome-04" align="center">
      <div class="app-update-close-button" @click="showDialog = false">✕</div>
      <AtomsFincsLogoSvg class="mb-8" :width="160" />

      <div class="text-dialog-description text-center">新しいバージョンが利用可能です。</div>
      <div class="text-dialog-description text-center">更新の上、ご利用ください。</div>

      <div class="d-flex justify-space-evenly mt-8">
        <AtomsDialogButton @click="clickReload"> 更新する </AtomsDialogButton>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .app-update-dialog {
    min-height: 146px;
    min-width: 345px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  .app-update-close-button {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
</style>
