export const consoleLog = (...data: any[]) => {
  if (useRuntimeConfig().public.appEnv !== "production") {
    console.log(...data);
  }
};

export const consoleWarn = (...data: any[]) => {
  if (useRuntimeConfig().public.appEnv !== "production") {
    console.warn(...data);
  }
};

export const consoleError = (...data: any[]) => {
  if (useRuntimeConfig().public.appEnv !== "production") {
    console.error(...data);
  }
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    consoleLog: (...data: any[]) => void;
    consoleWarn: (...data: any[]) => void;
    consoleError: (...data: any[]) => void;
  }
}
