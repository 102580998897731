import { useErrorStore } from "~/stores/ErrorStore";

export const PutS3Request = async (url: string, file: File) => {
  // ヘッダー
  const headers: { [name: string]: string } = {};
  headers["Content-Type"] = file.type;

  const { error } = await useFetch(url, {
    method: "PUT",
    headers: headers,
    body: file,
  });

  if (error.value) {
    const errorStore = useErrorStore();
    errorStore.showConnectionError(`PutS3Request: ${url} ${file}`);
    return { statusCode: error.value.statusCode };
  }
  return { statusCode: 200 };
};

export const CheckS3Expire = (url: string) => {
  // 署名付きURLを parse して判定
  // https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
  const params = url.substring(url.indexOf("?") + 1).split("&");
  const xAmzDate = params.find(v => v.startsWith("X-Amz-Date="))?.substring("X-Amz-Date=".length);
  const xAmzExpires = params.find(v => v.startsWith("X-Amz-Expires="))?.substring("X-Amz-Expires=".length);
  if (!xAmzDate || !xAmzExpires) {
    // 書式不正
    return false;
  }

  const dateStr = `${xAmzDate.substring(0, 4)}-${xAmzDate.substring(4, 6)}-${xAmzDate.substring(6, 8)}`;
  const timeStr = `${xAmzDate.substring(9, 11)}:${xAmzDate.substring(11, 13)}:${xAmzDate.substring(13, 15)}`;
  const date = new Date(`${dateStr}T${timeStr}Z`);
  if (Date.now() - date.getTime() >= Number(xAmzExpires) * 1000) {
    // 期限切れ
    return false;
  }

  return true;
};

export const GetContentType = async (url: string) => {
  // ヘッダー
  const headers: { [name: string]: string } = {};
  // 署名つきS3URLはHEADメソッドに対応していないので、GETでRangeで1byte取得して有効なURLかチェックする
  headers.Range = "bytes=0-1";

  let contentType = "";

  const { error } = await useFetch(url, {
    method: "GET",
    headers: headers,
    onResponse: context => {
      contentType = context.response.headers.get("content-type") ?? "";
    },
  });

  if (error.value) {
    return { statusCode: error.value.statusCode, contentType: undefined, error: error.value };
  }
  return { statusCode: 200, contentType: contentType };
};

// TODO 最終的には API から CFn URL が返ってくるのでそれまでの暫定処理
export const ConvertS3SignedUrlToCFnUrl = (signedUrl: string) => {
  const notSignedUrl = signedUrl.split("?")[0];
  const convNotSignedUrl = notSignedUrl?.replace(getS3Domain(), getCFnDomain());
  // TODO デバッグログ。あとで消す
  // console.log("ConvertS3SignedUrlToCFnUrl signedUrl", signedUrl);
  // console.log("ConvertS3SignedUrlToCFnUrl notSignedUrl", notSignedUrl);
  // console.log("ConvertS3SignedUrlToCFnUrl convNotSignedUrl", convNotSignedUrl);
  // // API から CFn URL が返ってくるようになっても正常動作することを確認済み
  // if (convNotSignedUrl !== signedUrl) {
  //   console.log("ConvertS3SignedUrlToCFnUrl recursively", ConvertS3SignedUrlToCFnUrl(convNotSignedUrl));
  // }
  return convNotSignedUrl;
};

const getS3Domain = () => {
  switch (useRuntimeConfig().public.appEnv) {
    case "staging":
      return "https://fincs-web-stg-contents.s3.ap-northeast-1.amazonaws.com/";
    case "production":
      return "https://fincs-web-prd-contents.s3.ap-northeast-1.amazonaws.com/";
    default:
      return "https://fincs-web-dev-contents.s3.ap-northeast-1.amazonaws.com/";
  }
};

const getCFnDomain = () => {
  switch (useRuntimeConfig().public.appEnv) {
    case "staging":
      return "https://contents.stg.fincs.jp/";
    case "production":
      return "https://contents.fincs.jp/";
    default:
      return "https://contents.dev.fincs.jp/";
  }
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    PutS3Request: (url: string, file: File) => Promise<{ statusCode: number }>;
    CheckS3Expire: (url: string) => boolean;
    GetContentType: (url: string) => Promise<{ statusCode: number; contentType: string | undefined; error: any }>;
    ConvertS3SignedUrlToCFnUrl: (signedUrl: string) => string;
  }
}
