<script setup lang="ts">
  // モーダルなYESNOダイアログ
  import { checkAppInBrowser, copyText } from "~/utils/Display";
  import { useSnackBarStore } from "~/stores/SnackBarStore";

  interface Props {
    isOpen: boolean;
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため
  }
  const props = withDefaults(defineProps<Props>(), {
    isOpen: false,
  });

  interface Emits {
    (event: "update:isOpen", isOpen: boolean): void;
  }

  const emit = defineEmits<Emits>();

  // スナックバーstore
  const snackBarStore = useSnackBarStore();

  const linkData = ref<{ type: string; url: string }>();

  const getLink = () => {
    const appType = checkAppInBrowser()!;

    const host = window.location.origin;
    const route = useRoute();
    let url = `${host}${route.path}`;
    let searchParams = queryToUrlSearchParams(route.query);

    if (route.path === "/login" && route.query.returnPath) {
      // ログイン画面の場合は、returnPathを取得
      const returnPath = route.query.returnPath ? (route.query.returnPath as string) : "/";
      // returnPathから pathとqueryを取得
      const returnPathUrl = new URL(returnPath, host);
      url = `${host}${returnPathUrl.pathname}`;
      searchParams = returnPathUrl.searchParams;
    }

    let type = "copy";

    if (appType?.includes("is_line")) {
      searchParams?.append("openExternalBrowser", "1");
      type = "jump";
    }

    return {
      type: type,
      url: `${url}` + (searchParams?.toString() ? `?${searchParams?.toString()}` : ""),
    };
  };

  const handleCopy = (url: string) => {
    copyText(url);
    snackBarStore.set("クリップボードにコピーしました");
  };

  const computedIsOpen = computed<boolean>({
    get: () => props.isOpen,
    set: value => {
      emit("update:isOpen", value);
    },
  });

  onMounted(() => {
    linkData.value = getLink();
  });
</script>

<template>
  <v-dialog v-model="computedIsOpen" max-width="906" scrim="black" opacity="0.8" :attach="!!isTest">
    <v-sheet class="dialog py-8 px-6 rounded" color="monochrome-04" align="center">
      <div>
        <AtomsFincsLogoSvg class="mb-2" :width="120" />
      </div>

      <template v-if="linkData?.type == 'jump'">
        <div class="text-dialog-title">Fincsへアクセスしてご利用ください</div>
        <div class="d-flex justify-space-evenly">
          <AtomsDialogButton class="mt-4" :href="linkData?.url"> Fincsを開く </AtomsDialogButton>
        </div>
      </template>

      <template v-if="linkData?.type == 'copy'">
        <div class="text-dialog-title">
          下のボタンを押して、URLをコピーし、SafariやGoogle Chromeなどのブラウザで開いてください
        </div>
        <div class="d-flex justify-space-evenly">
          <AtomsDialogButton class="mt-4" @click="handleCopy(linkData?.url)"> URLをコピーする </AtomsDialogButton>
        </div>
      </template>
    </v-sheet>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .dialog {
    min-height: 146px;
    min-width: 345px;
  }
</style>
