import * as Sentry from "@sentry/browser";
import { type SeverityLevel } from "@sentry/types";
import { isRef } from "vue";
import type { NuxtError } from "#app";

// NuxtError かどうかを判定
const isNuxtError = (value: any): value is NuxtError => {
  return value && typeof value === "object" && "statusCode" in value && "message" in value;
};

// sentry context として参照可能な値に変換 (参照不備を見つけたら都度処理追加する)
const convertContextValue = (value: any): any => {
  if (value === undefined) {
    // そのままだと省略されてしまうため文字列に変換
    return "[undefined]";
  } else if (value === null) {
    // そのままだと省略されてしまうため文字列に変換
    return "[null]";
  } else if (isRef(value)) {
    // ref の場合は .value を出力
    return convertContextValue(value.value);
  } else if (Array.isArray(value)) {
    // Array の場合は各要素を再帰的に変換
    return value.map(convertContextValue);
  } else if (isNuxtError(value)) {
    // NuxtError の場合は JSON.stringify する
    return JSON.stringify(value, Object.getOwnPropertyNames(value));
  } else if (value instanceof Error) {
    // その他の Error の場合は JSON.stringify が効かないのでそのまま出力
    return value;
  } else if (typeof value === "object") {
    // その他の object の場合は JSON.stringify する
    return JSON.stringify(value, Object.getOwnPropertyNames(value));
  }
  // 上記以外はそのまま返す
  return value;
};

// デバッグ用 sentry context 設定
const setDebugContext = (scope: Sentry.Scope, ...debugContexts: any): void => {
  // Record<string, any> に変換 (でないと適切に表示されない)
  const record: Record<string, any> = {};
  for (const i in debugContexts) {
    record[i] = convertContextValue(debugContexts[i]);
  }
  scope.setContext("Fincs Debug", record);
};

// sentry ログを送信
const sentryLog = (severityLevel: SeverityLevel, message: string, ...debugContexts: any): void => {
  // context をグローバルに設定したくないので、withScope で囲む
  Sentry.withScope(scope => {
    setDebugContext(scope, ...debugContexts);
    Sentry.captureMessage(message, severityLevel);
  });
};

// sentry error ログを送信
export const sentryErrorLog = (message: string, ...debugContexts: any): void => {
  // production 以外は console.error にも出力
  consoleError(`sentryErrorLog: ${message}`, debugContexts);
  sentryLog("error" as SeverityLevel, message, ...debugContexts);
};

// sentry warn ログを送信
export const sentryWarnLog = (message: string, ...debugContexts: any): void => {
  // production 以外は console.warn にも出力
  consoleWarn(`sentryWarnLog: ${message}`, debugContexts);
  sentryLog("warning" as SeverityLevel, message, ...debugContexts);
};

// sentry info ログを送信
export const sentryInfoLog = (message: string, ...debugContexts: any): void => {
  // production 以外は console.log にも出力
  consoleLog(`sentryInfoLog: ${message}`, debugContexts);
  sentryLog("info" as SeverityLevel, message, ...debugContexts);
};

// sentry user.id を設定
export const sentrySetUserId = (userId: string): void => {
  Sentry.setUser({
    id: userId,
  });
};

// sentry 例外ハンドリング
// 例外クラス名・メッセージ・スタックトレースが送信され、デバッグ用 context も設定可能
export const sentryHandleException = (exception: any, ...debugContexts: any) => {
  // production 以外は console.error にも出力
  consoleError(`sentryHandleException`, exception, debugContexts);

  // context をグローバルに設定したくないので、withScope で囲む
  Sentry.withScope(scope => {
    setDebugContext(scope, ...debugContexts);
    Sentry.captureException(exception);
  });
};

export const isSentrySampled = (isProduction: boolean): boolean => {
  const sampleRateForSpikingErrors = isProduction ? 0.01 : 1;
  return Math.random() <= sampleRateForSpikingErrors;
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    sentryErrorLog: (message: string, ...debugContexts: any) => void;
    sentryWarnLog: (message: string, ...debugContexts: any) => void;
    sentryInfoLog: (message: string, ...debugContexts: any) => void;
    sentrySetUserId: (userId: string) => void;
    sentryHandleException: (exception: any, ...debugContexts: any) => void;
    isSentrySampled: (isProduction: boolean) => boolean;
  }
}
