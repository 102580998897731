import { doc, setDoc, onSnapshot, Unsubscribe } from "firebase/firestore";
import type { ILineLinkStatusRepository, observerType } from "~/interfaces/ILineLinkStatusRepository";
import { LineLinkStatus, LineLinkStatusConverter, LineLinkStatusType } from "~/entities/LineLinkStatus";

/* firestoreのデータ構成
LineLinkStatuses(collection)
  - {userId:LineLinkStatus}(document)
  - {userId:LineLinkStatus}(document)
  - {userId:LineLinkStatus}(document)
*/

const collectionName = "LineLinkStatuses";

export class LineLinkStatusRepository implements ILineLinkStatusRepository {
  observer: observerType | undefined = undefined;
  unsubscribe: Unsubscribe | undefined = undefined;

  getDocQuery(userId: string) {
    const { $firestore } = useNuxtApp();
    return doc($firestore, collectionName, `${userId}`).withConverter(LineLinkStatusConverter);
  }

  async set(
    userId: string,
    planId: string,
    status: LineLinkStatusType,
    error: string,
    errorDescription: string,
  ): Promise<void> {
    try {
      const doc = this.getDocQuery(userId);
      await setDoc(doc, new LineLinkStatus(userId, planId, status, error, errorDescription, null));
    } catch (error: any) {
      throw new Error("Error set LineLinkStatus: ", error);
    }
  }

  startObserve(userId: string, observer: observerType): void {
    this.stopObserve();
    this.observer = observer;
    const doc = this.getDocQuery(userId);
    this.unsubscribe = onSnapshot(doc, snapshot => {
      if (snapshot.exists()) {
        if (this.observer) {
          this.observer(snapshot.data());
        }
      }
    });
  }

  stopObserve() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = undefined;
    }
  }
}
