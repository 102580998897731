import { initializeApp, type FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { defineNuxtPlugin } from "#app";
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const firebaseConfig = {
    apiKey: config.public.FIREBASE_API_KEY,
    authDomain: config.public.FIREBASE_AUTH_DOMAIN,
    projectId: config.public.FIREBASE_PROJECT_ID,
    messagingSenderId: config.public.FIREBASE_MESSAGING_SENDER_ID,
    measurementId: config.public.FIREBASE_MEASUREMENT_ID,
    vapidKey: config.public.FIREBASE_VAPID_KEY,
    appId: config.public.FIREBASE_API_ID,
  };
  const app = initializeApp(firebaseConfig);

  const firebaseAuth = initFirebaseAuth(app);

  const firestore = initFirestore(app);

  const getMessagingWithCheck = () => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      try {
        return getMessaging(app);
      } catch (error: any) {}
    }
    return undefined;
  };
  const messaging = getMessagingWithCheck();
  // Firestoreに接続するための情報をアプリケーション全体に提供
  // 使い方
  // const { $firestore } = useNuxtApp()
  return {
    provide: {
      firebaseAuth: firebaseAuth,
      firestore: firestore,
      messaging: messaging,
      firebaseConfig: firebaseConfig,
    },
  };
});

function initFirebaseAuth(app: FirebaseApp) {
  // Auth の初期化
  // firestore security rules を動作させるためにここで呼ぶ必要あり
  const auth = getAuth(app);
  return auth;
}

const FIRESTORE_CACHE_EXPIRATION_SEC = 24 * 60 * 60; // FireStoreキャッシュ有効時間
function initFirestore(app: FirebaseApp) {
  // localStorage 上限は 5MiB で、超えると firestore が動作しなくなる。
  // よって、localStorage の容量を確認し 3 MiB を超えている場合は関連データを削除する。
  let hasRemovedFirestoreFromLocalStorage = false;
  const localStorageSize = new Blob(Object.values(localStorage)).size;
  consoleLog(`localStorageSize: ${localStorageSize}`);
  if (localStorageSize > 3 * 1024 * 1024) {
    for (const key in localStorage) {
      if (key.startsWith("firestore_")) {
        localStorage.removeItem(key);
        hasRemovedFirestoreFromLocalStorage = true;
      }
    }
  }

  if (featureFirestoreCache()) {
    // キャッシュ有効時

    // キャッシュ初期化関数定義
    const initFirestoreCache = () => {
      consoleLog("initFirestoreCache");

      localStorage.setItem(
        "FireStoreCache",
        JSON.stringify({
          createdAt: Date.now(),
        }),
      );

      // Call this function before initializing Firestore to clear the cache.
      // Note: This is a forceful approach and might not be suitable for all applications.
      // Firebase SDKのバージョン変更時には要注意
      clearFirestoreCache();
    };

    if (hasRemovedFirestoreFromLocalStorage) {
      // 上記で localStorage の削除を実行した場合、キャッシュも初期化
      initFirestoreCache();
    } else {
      // 上記で localStorage の削除を実行しなくても
      // 一定時間ごとにキャッシュを削除
      // キャッシュが増えすぎるとクエリに時間がかかるので定期的に消す
      const firestoreInfoStr = localStorage.getItem("FireStoreCache");
      if (firestoreInfoStr) {
        const firestoreInfo = JSON.parse(firestoreInfoStr);
        if (Date.now() - firestoreInfo.createdAt > FIRESTORE_CACHE_EXPIRATION_SEC * 1000) {
          initFirestoreCache();
        }
      } else {
        initFirestoreCache();
      }
    }

    return initializeFirestore(app, {
      experimentalAutoDetectLongPolling: true,
      // キャッシュは永続化する(memoryLocalCacheではキャッシュがヒットしない)
      // persistentLocalCacheを指定すると、キャッシュはindexedDBに保存される
      localCache: persistentLocalCache({
        // https://firebase.google.com/docs/reference/js/firestore_.persistentcachesettings
        // cacheSizeBytes: CACHE_SIZE_UNLIMITED, // 無制限
        // cacheSizeBytes: 1 * 1000 * 1000, // ミニマム 1 MB
        cacheSizeBytes: 40 * 1000 * 1000, // デフォルト 40 MB
        tabManager: persistentMultipleTabManager(), // 複数タブで同期させる
      }),
    });
  } else {
    // キャッシュ無効時
    return getFirestore(app);
  }
}

// This function attempts to clear the Firestore cache by deleting the IndexedDB databases used by Firestore.
// WARNING: Use this with caution. It's a direct manipulation that could lead to unexpected behavior.
function clearFirestoreCache() {
  const projectId = useRuntimeConfig().public.FIREBASE_PROJECT_ID;
  const databasesToDelete = ["firestore/[DEFAULT]/" + projectId + "/main"];
  databasesToDelete.forEach(dbName => {
    const request = indexedDB.deleteDatabase(dbName);
    request.onerror = event => {
      sentryErrorLog(`clearFirestoreCache: Error deleting database "${dbName}". event: ${JSON.stringify(event)}`);
      // console.error(`Error deleting database "${dbName}".`, event);
    };
    // request.onsuccess = () => {
    //   console.log(`Database "${dbName}" deleted successfully.`);
    // };
  });
}
