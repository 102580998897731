import { defineStore } from "pinia";
import { useLoginStore } from "./LoginStore";
import type { IContentsRepository } from "~/interfaces/IContentsRepository";
import { ContentsRepository } from "~/Repositories/ContentsRepository";
import { UserContentsInfo } from "~/entities/UserContentsInfo";
import { useErrorStore } from "~/stores/ErrorStore";

export const useContentsStore = defineStore("ContentsStore", () => {
  const loginStore = useLoginStore();
  const errorStore = useErrorStore();
  const repository: IContentsRepository = new ContentsRepository();
  const userContentsInfo = ref<UserContentsInfo | undefined>();
  const prevUserId = ref<string | undefined>();
  const prevContentsId = ref<string | undefined>();

  /**
   * コンテンツの公開非公開情報を変更する
   */
  const putPublish = async (contentsId: string, publishStatus: string) => {
    await repository.putPublish(contentsId, publishStatus);
  };

  /**
   * コンテンツ閲覧
   */
  const postBrowse = async (contentsId: string, userId: string) => {
    await repository.postBrowse(contentsId, userId);
  };

  /**
   * コンテンツ視聴完了
   */
  const postComplete = async (contentsId: string) => {
    await repository.postComplete(contentsId);
  };

  /**
   * いいねする
   */
  const postGood = async (contentsId: string) => {
    await repository.postGood(contentsId);
  };

  /**
   * いいね解除
   */
  const deleteGood = async (contentsId: string) => {
    await repository.deleteGood(contentsId);
  };

  /**
   * ユーザーごとのコンテンツ情報を取得
   */
  const fetchUserContentsInfo = async (userId: string, contentsId: string) => {
    userContentsInfo.value = await repository.getUserContentsInfo(userId, contentsId);
    prevUserId.value = userId;
    prevContentsId.value = contentsId;
  };

  /**
   * ユーザーごとのコンテンツ情報を取得
   */
  const getUserContentsInfo = async (userId: string, contentsId: string): Promise<UserContentsInfo> => {
    return await repository.getUserContentsInfo(userId, contentsId);
  };

  /**
   * ユーザーごとのコンテンツ情報を更新
   */
  const putUserContentsInfo = async (userId: string, contentsId: string, info: UserContentsInfo) => {
    await repository.putUserContentsInfo(userId, contentsId, info);
  };

  /**
   * ユーザーごとのcurrentTimeを更新。maxTimeも更新できたら更新する
   */
  const putCurrentTime = async (
    userId: string,
    contentsId: string,
    currentTime: number,
    duration: number | undefined,
    isComplete: boolean | undefined,
  ) => {
    // 前回の値と比較するためデータを取得する
    await fetchUserContentsInfo(userId, contentsId);

    const info = userContentsInfo.value ?? UserContentsInfo.empty();
    info.currentTime = currentTime;
    info.maxTime = Math.max(currentTime, info.maxTime);

    await repository.putUserContentsInfo(userId, contentsId, info);

    // 視聴完了の場合は視聴完了APIをコールする
    if (!isComplete && duration && info.maxTime >= duration) {
      await postComplete(contentsId);
    }

    // 通信量削減のためfirestoreから取得ではなく、ローカルの値を更新する
    userContentsInfo.value = info;
  };

  /**
   * ユーザーごとのmaxPageを更新
   */
  const putMaxPage = async (
    userId: string,
    contentsId: string,
    maxPage: number,
    totalPage: number,
    isComplete: boolean | undefined,
  ) => {
    // 視聴完了の場合はなにもしない
    if (isComplete) return;

    // 前回の値と比較するためデータを取得する
    if (prevUserId.value !== userId || prevContentsId.value !== contentsId) {
      await fetchUserContentsInfo(userId, contentsId);
    }

    const info = userContentsInfo.value ?? UserContentsInfo.empty();

    // 既にmaxPageが大きい場合は更新しない
    if (info.maxPage >= maxPage) return;

    info.maxPage = Math.max(maxPage, info.maxPage);

    await repository.putUserContentsInfo(userId, contentsId, info);

    // 視聴完了の場合は視聴完了APIをコールする
    if (!isComplete && info.maxPage >= totalPage) {
      await postComplete(contentsId);
    }

    // 通信量削減のためfirestoreから取得ではなく、ローカルの値を更新する
    userContentsInfo.value = info;
  };

  /**
   * コンテンツ情報を取得
   * @param page ページ番号
   * @param pageSize 1ページあたりの件数
   * @param options contents_types: コンテンツタイプリスト([movie/live_movie/archive_movie/archive_movie_progress/document/schedule])
   * @param options exclusion_contents_id: 除外するコンテンツID
   * @param options is_contracted: 契約済みのコンテンツのみ取得するか
   * @param options is_free: 無料のコンテンツのみ取得するか
   * @param options owner_user_id: コンテンツの所有者のユーザーID
   * @param options plan_id: プランID
   * @param options search_word: 検索キーワード
   * @param options sort: ソート順[desc/asc]（nullの場合desc)
   * @param options tag: タグ
   * @param options user_role: ユーザーのロール
   * @param options skipUnreadCheck: 未読チェックをスキップするか。未読チェックは30個までという制限があるのでそれ以上取得する場合はtrueにする
   */
  const getList = async <T>(page: number, pageSize: number, options?: Record<string, any>) => {
    interface ContentsList {
      contents: { id: number; isUnread: boolean }[];
    }

    const skipUnreadCheck = options?.skipUnreadCheck;
    // skipUnreadCheckはAPIリクエストには使用しないのでoptionsから削除
    if (options) {
      delete options.skipUnreadCheck;
    }

    const contents = (await repository.getList<T>(page, pageSize, options)) as ContentsList;

    // ログイン中の場合、ユーザーごとのコンテンツ既読状態を付与
    const userId = loginStore.loginInfo.userId;
    if (userId && skipUnreadCheck !== true && contents.contents.length > 0) {
      // TODO 暫定的にすべて未読で初期化
      // API レスポンスから is_unread がなくなったら isUnread は undefined でここに来るので、
      // そうなったらこの forEach は削除する
      contents.contents.forEach(content => {
        content.isUnread = true;
      });

      // Firestore からユーザーコンテンツリスト取得
      const userContents = await repository.getUserContentsList(
        userId,
        contents.contents.map(content => content.id.toString()),
      );

      // 既読状態を設定
      userContents.forEach(userContent => {
        const content = contents.contents.find(content => content.id.toString() === userContent.id);
        content!.isUnread = !userContent.read;
      });
    }
    return contents as T;
  };

  const getDetail = async <T>(contentsId: string) => {
    // コンテンツ取得に失敗した場合には無効なURLエラーダイアログを表示する
    try {
      return (await repository.getDetail(contentsId)) as T;
    } catch (error: any) {
      errorStore.showInvalidContents(`コンテンツID:${contentsId} の取得に失敗しました。${error.message}`);
      return undefined;
    }
  };

  return {
    userContentsInfo,
    putPublish,
    postBrowse,
    postComplete,
    postGood,
    deleteGood,
    fetchUserContentsInfo,
    getUserContentsInfo,
    putUserContentsInfo,
    putCurrentTime,
    putMaxPage,
    getList,
    getDetail,
  };
});
