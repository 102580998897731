import { defineStore } from "pinia";
import {
  type PLAN_TYPE,
  PLAN_TYPE_LIST,
  PLAN_TYPE_LABEL_LIST,
  PLAN_TYPE_DEFAULT,
  getPlanTypeLabel,
} from "~/consts/contentsConfig";

export const useNavigationStore = defineStore(
  "NavigationStore",
  () => {
    // 現在見ている講座タイプ
    const planType = ref<PLAN_TYPE>(PLAN_TYPE_DEFAULT);

    // 講座タイプのlabel
    const planTypeLabel = computed(() => {
      return getPlanTypeLabel(planType.value);
    });

    // 初期化
    const reset = () => {
      planType.value = PLAN_TYPE_DEFAULT;
    };

    // 講座タイプをセット
    const setPlanType = (type: PLAN_TYPE | undefined) => {
      if (type) {
        planType.value = type;
      }
    };

    // labelから講座タイプをセット
    const setPlanTypeByLabel = (label: string) => {
      const index = PLAN_TYPE_LABEL_LIST.indexOf(label);
      if (index !== -1) {
        planType.value = PLAN_TYPE_LIST[index] as PLAN_TYPE;
      }
    };

    // routeから講座タイプをセット
    const setPlanTypeByQuery = () => {
      const route = useRoute();
      if (!route.query.planType) {
        return;
      }
      // 大文字にする
      const pType = (route.query.planType as string).toUpperCase();
      if (PLAN_TYPE_LIST.includes(pType as PLAN_TYPE)) {
        planType.value = pType as PLAN_TYPE;
      }
    };

    // 講座タイプによってrouteのqueryを変更
    const changeQueryByPlanType = () => {
      const route = useRoute();
      const query = { ...route.query };
      // デフォルトの場合はqueryを削除
      if (planType.value === PLAN_TYPE_DEFAULT) {
        delete query.planType;
      } else {
        query.planType = planType.value.toLowerCase();
      }
      useRouter().replace({ query });
    };

    return {
      planType,
      planTypeLabel,
      reset,
      setPlanType,
      setPlanTypeByLabel,
      setPlanTypeByQuery,
      changeQueryByPlanType,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
