import { Mutex, tryAcquire, E_ALREADY_LOCKED } from "async-mutex";
import { defineStore } from "pinia";
import { setUserId } from "../utils/WebPushUser";
import type { AuthProviderType } from "./AuthStore";
import { LoginInfo } from "~/entities/LoginInfo";
import type { ILoginRepository } from "~/interfaces/ILoginRepository";
import { LoginRepository } from "~/Repositories/LoginRepository";

export const useLoginStore = defineStore(
  "LoginStore",
  () => {
    // 他の store 同様に inject したいが、この LoginStore は middleware/auth から呼ばれ、
    // かつ Nuxt 3 では inject は setup コンテキスト中でしか使えず middleware では使えない。
    // よって、やむを得ず直接インスタンスを生成する
    const repository: ILoginRepository = new LoginRepository();

    // ログイン情報
    const loginInfo = ref<LoginInfo>(new LoginInfo());

    // デバイストークン
    const deviceToken = ref<string | null>(null);
    // デバイストークン送信日時
    const deviceTokenSentDate = ref<number | null>(null);
    // 前回ログイン時のプロバイダー情報
    const prevProviderType = ref<AuthProviderType | null | undefined>(null);
    // リダイレクトログイン後かどうか
    const isSignInWithRedirect = ref<boolean>(false);
    // メール登録ずみかチェックするかどうか。v2.6.1ログイン改修以前のユーザーはfalseにして、メール登録済みかチェックしないようにする。
    const isEmailCheckEnabled = ref<boolean>(false);

    /**
     * GTMで使用するユーザーIDをCookieにセットする
     */
    const setCookieForGTM = async () => {
      let needReload = false;
      // GTMで使用するユーザーID用Cookie。maxAgeはsafariで設定できる最大値の7日としておく
      const cookieUserId = useCookie("fincs_userid", { maxAge: 604800 });

      if (loginInfo.value?.userId && !cookieUserId.value) {
        needReload = true;
      }
      // maxAgeを再設定したいので一度nullにして削除する
      cookieUserId.value = null;
      // 削除後に再設定するときに値が反映されないことがあるので、少し待ってから再設定する
      await new Promise(resolve => setTimeout(resolve, 10));
      if (loginInfo.value?.userId) {
        cookieUserId.value = loginInfo.value?.userId;
      }
      return needReload;
    };

    // ログインを行う
    const login = async (idToken: string) => {
      const info = await repository.login(idToken);
      await setLoginInfo(info);
    };

    // 新規登録を行う
    const register = async (idToken: string) => {
      const info = await repository.register(idToken);
      await setLoginInfo(info);
    };

    // 管理者ログインを行う
    const adminLogin = async (idToken: string) => {
      const info = await repository.adminLogin(idToken);
      await setLoginInfo(info);
    };

    const refreshMutex = new Mutex();
    const refresh = async () => {
      // リフレッシュトークンを使ってセッショントークンを更新する
      // 非同期で複数リクエスト発生する場合があるので、排他制御で最初の要求のみを処理し、以降は待機させる
      try {
        await tryAcquire(refreshMutex).runExclusive(async () => {
          const info = await repository.refresh(loginInfo.value.refreshToken);
          loginInfo.value.sessionToken = info.sessionToken;
          loginInfo.value.refreshToken = info.refreshToken;
          await setCookieForGTM();
        });
      } catch (error: any) {
        if (error === E_ALREADY_LOCKED) {
          await refreshMutex.waitForUnlock();
        } else {
          throw error;
        }
      }
    };

    // 指定のメールアドレスに認証コードを送信し、auth_codeを返却
    const registEmailNotAuthUser = async (email: string) => {
      return await repository.registEmailNotAuthUser(email);
    };

    // メールアドレスと認証コードを送信して有効性チェック
    const validateEmailNotAuthUser = async (email: string, authCode: string, mailAuthKey: string) => {
      return await repository.validateEmailNotAuthUser(email, authCode, mailAuthKey);
    };

    // メールアドレスを登録する
    const authEmail = async (email: string, authCode: string, mailAuthKey: string) => {
      return await repository.authEmail(email, authCode, mailAuthKey);
    };

    // パスワード変更したいメールアドレスに認証コードを送信し、auth_codeを返却
    const registEmailChangePassword = async (email: string) => {
      return await repository.registEmailChangePassword(email);
    };

    // メールアドレスと認証コードを送信してパスワード変更
    const changePassword = async (email: string, authCode: string, mailAuthKey: string, newPassword: string) => {
      return await repository.changePassword(email, authCode, mailAuthKey, newPassword);
    };

    const setLoginInfo = async (newLoginInfo: LoginInfo) => {
      loginInfo.value = newLoginInfo;
      // v2.6.1ログイン改修後のログインの場合はメール登録済みかどうかチェックフラグを立てる
      isEmailCheckEnabled.value = true;
      deviceToken.value = null;
      await setCookieForGTM();
      await setUserId(loginInfo.value?.userId);
    };

    const setDeviceToken = (token: string | null): void => {
      deviceToken.value = token;
      deviceTokenSentDate.value = new Date().getTime();
    };

    const shouldSendDeviceToken = (token: string): boolean => {
      if (deviceToken.value !== token) {
        return true;
      }
      if (!deviceTokenSentDate.value) {
        return true;
      }
      const now = new Date().getTime();
      // 12時間以上経過していたら再送信
      return now - deviceTokenSentDate.value > 12 * 60 * 60 * 1000;
    };

    const logout = async () => {
      loginInfo.value = new LoginInfo();
      deviceToken.value = null;
      await setCookieForGTM();
      await setUserId("");
    };

    /**
     * ログイン済みかどうか
     */
    const isLogin = (): boolean => {
      return loginInfo.value?.userId?.length > 0 && isEmailRegistered();
    };

    /**
     * 配信者かどうか
     */
    const isOwner = (): boolean => {
      return loginInfo.value?.userRoleCode === "owner";
    };

    /**
     * 管理者かどうか
     */
    const isAdmin = (): boolean => {
      return loginInfo.value?.userRoleCode === "administrator";
    };

    /**
     * メールアドレスを登録ずみかどうか
     */
    const isEmailRegistered = (): boolean => {
      // owner, administratorはメール登録済みとみなす
      if (loginInfo.value?.userRoleCode === "owner" || loginInfo.value?.userRoleCode === "administrator") {
        return true;
      }

      // メール登録ずみかどうかチェックする場合はチェック結果を返す
      return loginInfo.value?.isEmailRegistered || !isEmailCheckEnabled.value;
    };

    const setEmailRegistered = (isEmailRegistered: boolean) => {
      loginInfo.value.isEmailRegistered = isEmailRegistered;
    };

    /**
     * セッショントークンを取得
     * @returns セッショントークン
     */
    const getSessionToken = (): string => {
      if (loginInfo.value?.sessionToken && loginInfo.value?.sessionToken.length > 0) {
        return loginInfo.value?.sessionToken;
      }
      return "";
    };

    return {
      loginInfo,
      deviceToken,
      deviceTokenSentDate,
      prevProviderType,
      isSignInWithRedirect,
      isEmailCheckEnabled,
      setCookieForGTM,
      login,
      register,
      adminLogin,
      refresh,
      registEmailNotAuthUser,
      validateEmailNotAuthUser,
      authEmail,
      registEmailChangePassword,
      changePassword,
      setLoginInfo,
      setDeviceToken,
      shouldSendDeviceToken,
      logout,
      isLogin,
      isOwner,
      isAdmin,
      isEmailRegistered,
      setEmailRegistered,
      getSessionToken,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
