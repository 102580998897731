<script setup lang="ts">
  interface Props {
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため
  }
  defineProps<Props>();

  const modal = ref(false);

  const openModal = () => {
    modal.value = true;
  };

  defineExpose({
    openModal,
  });
</script>

<template>
  <!-- ブラウザーインストールモーダル用処理 -->
  <v-dialog v-model="modal" width="906" scrim="black" :attach="!!isTest">
    <v-card class="dialog">
      <v-btn class="closeBtn" icon="$close" variant="text" @click="modal = false"></v-btn>

      <div class="container">
        <div class="text-dialog-title mb-4">Webプッシュ通知の受け取り方法</div>
        <div class="text-dialog-description text-left">
          <div>Webプッシュ通知を受け取るには、Webアプリをホーム画面に追加する必要があります。</div>
          <div>
            以下の手順に従って、Webアプリをホーム画面に追加し、その後アプリを開いてWebプッシュ通知の受け取りを登録してください。
          </div>
          <div class="text-main-04">
            ※iOS16.4未満では、ホーム画面への追加後もプッシュ通知を受け取ることができません。バージョンアップをお願いします。
          </div>
        </div>

        <div class="divider"></div>

        <div class="text-dialog-title mb-4">Google Chromeの場合</div>
        <div class="text-dialog-description">
          <div>
            1. アドレスバーの右横にある共有<img
              width="20"
              height="20"
              src="assets/images/icon/export-variation.svg"
            />をタップします。
          </div>
          <div>2.「ホーム画面に追加」をタップします。</div>
          <div>3.「追加」をタップします。</div>
        </div>

        <div class="divider"></div>

        <div class="text-dialog-title mb-4">Safariの場合</div>
        <div class="text-dialog-description">
          <div>
            1. アドレスバーの下にある共有<img
              width="20"
              height="20"
              src="assets/images/icon/export-variation.svg"
            />をタップします。
          </div>
          <div>2. [ホーム画面に追加] を見つけてタップします。</div>
          <div>3. ホーム画面からFincsアプリを開き、Webプッシュ通知の登録を行なってください。</div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
  @import "assets/media.scss";

  .dialog {
    position: relative;
    text-align: center;
    background-color: #222222;
    padding: 40px 60px;
    @include display_sp {
      padding: 40px 20px;
    }
    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .divider {
      margin: 20px 20%;
      border-bottom: 1px solid rgba(var(--v-theme-monochrome-01), 0.1);
    }
  }
</style>
