import { defineStore } from "pinia";
import {
  signOut as firebaseSignOut,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  type AuthProvider as FirebaseAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
  type UserCredential,
  type User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  RecaptchaVerifier,
  type MultiFactorResolver,
  getMultiFactorResolver,
  type MultiFactorError,
  PhoneMultiFactorGenerator,
  type PhoneMultiFactorInfo,
  PhoneAuthProvider,
  browserLocalPersistence,
  multiFactor,
  type PhoneInfoOptions,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";

import { useTalkRoomViewStore } from "./TalkRoomViewStore";
import { useReceiptStore } from "./ReceiptStore";
import { useLoginStore } from "~/stores/LoginStore";
import { useSnackBarStore } from "~/stores/SnackBarStore";

export type AuthType = "signin" | "signup";
export type AuthProviderType = "google" | "x" | "facebook" | "email";

export const enum SignInWithEmailResponse {
  EmailNotVerified = 1,
  MfaNotRegist = 2,
  MfaRequired = 3,
}

export const isFirebaseError = (arg: unknown): arg is FirebaseError => {
  return arg !== null && typeof arg === "object" && typeof (arg as FirebaseError).code === "string";
};

export const useAuthStore = defineStore("AuthStore", () => {
  const snackBarStore = useSnackBarStore();
  const loginStore = useLoginStore();
  const talkRoomViewStore = useTalkRoomViewStore();
  const receiptStore = useReceiptStore();

  // ベースURLを取得
  const getBaseUrl = () => window.location.origin;

  // sessionTokenを保持
  const token = ref<string | null>(null);

  // 未登録かどうかのフラグ
  const isNotRegistered = ref<boolean>(false);

  // 2段階認証時にログイン時のerrorを使用するので取っておく
  const firebaseError = ref<FirebaseError | undefined>(undefined);

  // 2段階認証で使用する変数
  let recaptchaVerifier: RecaptchaVerifier | null = null;
  let resolver: MultiFactorResolver | null = null;
  let recaptchaId = -1;
  let recaptchaElementId = "";
  let verificationId = "";

  // 2段階認証デバイス
  const mfaDevice = ref<string>("");

  const getAuth = () => {
    // pluginで定義したfirebaseAuthを取得
    const { $firebaseAuth } = useNuxtApp();
    return $firebaseAuth;
  };

  const signOut = async (): Promise<void> => {
    // localStorageに保存しているものはログアウト時にリセットする
    talkRoomViewStore.reset();
    receiptStore.reset();

    await loginStore.logout();
    const auth = getAuth();
    await firebaseSignOut(auth);
    token.value = null;
  };

  const checkAuthState = (): void => {
    if (process.server) return;
    if (loginStore.loginInfo.sessionToken) {
      // TODO: 認証チェック処理を記述。一旦簡易的にセッションTokenの有無のみで判別
      token.value = loginStore.loginInfo.sessionToken;
    } else {
      token.value = null;
    }
  };

  // Google
  const signInWithGoogle = async (type: AuthType, isRedirect: boolean): Promise<void> => {
    const provider = new GoogleAuthProvider();
    // Googleログイン時にアカウントが一つでも選択ポップアップを表示する (Googleのみ可能)
    // https://fincs.backlog.com/view/FINCS-1672
    provider.setCustomParameters({ prompt: "select_account" });
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
    await signInByAuthProvider(isRedirect, provider);
  };

  // X
  const signInWithX = async (type: AuthType, isRedirect: boolean): Promise<void> => {
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
    await signInByAuthProvider(isRedirect, new TwitterAuthProvider());
  };

  // Facebook
  const signInWithFacebook = async (type: AuthType, isRedirect: boolean): Promise<void> => {
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
    await signInByAuthProvider(isRedirect, new FacebookAuthProvider());
  };

  const signInByAuthProvider = async (isRedirect: boolean, provider: FirebaseAuthProvider): Promise<void> => {
    beforeSignIn();
    const auth = getAuth();
    if (isRedirect) {
      loginStore.isSignInWithRedirect = true;
      await signInWithRedirect(auth, provider);
      return;
    }
    loginStore.isSignInWithRedirect = false;
    const userCredential = await signInWithPopup(auth, provider);
    // 認証済みなら token をセット
    token.value = await getAuthenticatedToken(userCredential.user);
  };

  const afterSignInWithRedirect = async (): Promise<boolean> => {
    const auth = getAuth();
    const userCredential = await getRedirectResult(auth);
    if (!userCredential || !userCredential.user) {
      return false; // 未認証なので何もしない
    }
    // 認証済みなら token をセット
    token.value = await getAuthenticatedToken(userCredential.user);
    return true;
  };

  const getAuthenticatedToken = async (user: User): Promise<string | null> => {
    try {
      const idToken = await user.getIdToken();
      const authType = useCookie("auth_type", { maxAge: 604800 });
      if (authType.value === "signin") {
        await loginStore.login(idToken);
      } else if (authType.value === "signup") {
        await loginStore.register(idToken);
      }
      return loginStore.getSessionToken();
    } catch (error: any) {
      if (error.data?.status_code === 404) {
        // 未登録フラグを立てる
        isNotRegistered.value = true;
        sentrySetUserId(user.uid);
        sentryErrorLog(`login error: 未登録`, error);
      } else if (error.data?.status_code === 409) {
        snackBarStore.set("すでに登録済みのアカウントです。ログインタブからログインを行なってください。", "error");
      } else {
        // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
        sentrySetUserId(user.uid);
        sentryErrorLog(`login error: ${error.message}`, error);
      }
      throw error;
    }
  };

  /**
   * email,passwordでログイン
   * @param email
   * @param password
   * @returns
   */
  const signInWithEmail = async (type: AuthType, email: string, password: string): Promise<void> => {
    beforeSignIn();
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
    const auth = getAuth();
    if (type === "signup") {
      // 新規登録
      let userCredential: UserCredential;
      try {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } catch (error: any) {
        if (isFirebaseError(error) && error.code === "auth/email-already-in-use") {
          // api authEmailが失敗した場合はfirebase登録済み、fincs登録済み、メール未登録となる。
          // そのためfirebase登録済みでもfincs登録済みでもエラーを返さないようにする。
          // firebase登録済みの場合はログイン処理を行う
          userCredential = await signInWithEmailAndPassword(auth, email, password);
        } else {
          throw error;
        }
      }
      if (userCredential) {
        // 認証済みなら token をセット
        token.value = await getAuthenticatedTokenEmailSignup(userCredential.user);
      } else {
        throw new Error("新規登録に失敗しました");
      }
    } else {
      // ログイン
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // 認証済みなら token をセット
        token.value = await getAuthenticatedToken(userCredential.user);
      } catch (error: any) {
        if (isFirebaseError(error) && error.code === "auth/user-not-found") {
          isNotRegistered.value = true;
        }
        throw error;
      }
    }
  };

  // email新規登録はフローが複雑なので別途処理
  // api authEmailが失敗した場合はfirebase登録済み、fincs登録済み、メール未登録となる。
  // そのためfirebase登録済みでもfincs登録済みでもエラーを返さないようにする。
  const getAuthenticatedTokenEmailSignup = async (user: User): Promise<string | null> => {
    try {
      const idToken = await user.getIdToken();
      try {
        await loginStore.register(idToken);
        return loginStore.loginInfo.sessionToken;
      } catch (error: any) {
        if (error.data?.status_code === 409) {
          // すでに登録済みのアカウントの場合はログイン処理を行う
          await loginStore.login(idToken);
          return loginStore.loginInfo.sessionToken;
        }
      }
    } catch (error: any) {
      // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
      sentrySetUserId(user.uid);
      sentryErrorLog(`login error: ${error.message}`, error);
    }
    return null;
  };
  /**
   * 管理者ログイン用の認証トークンを取得する
   * @param user
   * @returns
   */
  const getAuthenticatedTokenAdmin = async (user: User): Promise<string | null> => {
    try {
      const idToken = await user.getIdToken();
      await loginStore.adminLogin(idToken);
      return loginStore.loginInfo.sessionToken;
    } catch (error: any) {
      // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
      sentrySetUserId(user.uid);
      sentryErrorLog(`adminLogin error:: ${error.message}`, error);
      return null;
    }
  };

  // email,passwordで登録
  const signUpWithEmailForAdmin = async (email: string, password: string): Promise<void> => {
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, email, password);
  };

  /**
   * email,passwordで管理者ログイン
   * 処理フロー
   * email認証NG -> SignInWithEmailResponse.EmailNotVerifiedでメール認証させる
   * email認証OK、2段階認証登録まだ -> SignInWithEmailResponse.MfaNotRegistで2段階認証登録させる
   * email認証OK、2段階認証登録済み -> auth/multi-factor-auth-requiredとなるので２段階認証をさせる
   * @param email
   * @param password
   * @returns
   */
  const signInWithEmailForAdmin = async (email: string, password: string): Promise<SignInWithEmailResponse> => {
    try {
      const auth = getAuth();
      await auth.setPersistence(browserLocalPersistence);
      const result = await signInWithEmailAndPassword(auth, email, password);
      if (!result.user.emailVerified) {
        // email認証がまだ
        return SignInWithEmailResponse.EmailNotVerified;
      }
    } catch (error: any) {
      if (!isFirebaseError(error) || error.code !== "auth/multi-factor-auth-required") {
        throw error;
      }
      firebaseError.value = error as FirebaseError;
      // catchの中なので紛らわしいが、ここに来るのが正常終了。この後２段階認証をしてもらう
      return SignInWithEmailResponse.MfaRequired;
    }
    // 管理者ログインは2段階認証必須なのでここに到達するということは、2段階認証が登録されていないということ。
    return SignInWithEmailResponse.MfaNotRegist;
  };

  // email認証のためのメールを送信する
  const sendVerifyEmail = async (verifyPath: string): Promise<void> => {
    try {
      const auth = getAuth();
      if (!auth.currentUser) {
        await navigateTo("/admin/signup");
        return;
      }
      await sendEmailVerification(auth.currentUser, {
        url: getBaseUrl() + verifyPath,
        handleCodeInApp: true,
      });
    } catch (error: any) {
      alert(error);
    }
  };

  /**
   * Recaptchaを開始する
   * @param elementId divなどのHTML内でrecaptchaを描画するHTMLのidを指定する
   */
  const setRecaptcha = async (elementId: string): Promise<void> => {
    const auth = getAuth();
    recaptchaElementId = elementId;
    recaptchaVerifier = new RecaptchaVerifier(auth, elementId, {
      size: "invisible",
    });
    recaptchaId = await recaptchaVerifier.render();
  };

  /**
   * MFAデバイスを列挙し、最初のデバイス名をmfaDeviceに格納する
   */
  const getMFADevice = (): void => {
    const auth = getAuth();
    resolver = getMultiFactorResolver(auth, firebaseError.value as MultiFactorError);
    if (!resolver) {
      throw new Error("resolverを作成できませんでした");
    }
    const mfaDevices = resolver.hints.map(item => {
      if (item.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        return (item as PhoneMultiFactorInfo).phoneNumber ?? "unknown device";
      }
      return "unknown device";
    });
    if (mfaDevices) {
      mfaDevice.value = mfaDevices[0];
    }
  };

  /**
   * 2段階認証コードをデバイスに送信し登録する
   */
  const registMFADevice = async (phoneNumber: string): Promise<void> => {
    const auth = getAuth();
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth || !auth.currentUser) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("送信に失敗しました。ログインし直してください。");
    }
    try {
      const multiFactorSession = await multiFactor(auth.currentUser).getSession();
      const phoneInfoOptions: PhoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      };
      verificationId = await new PhoneAuthProvider(auth).verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    } catch (error: any) {
      // しばらくログインしてない場合はエラーになるのでログインを再度求める
      if (isFirebaseError(error) && error.code === "auth/requires-recent-login") {
        throw new Error("送信に失敗しました。ログインし直してください。");
      }
      throw error;
    }
  };

  /**
   * 2段階認証コードを登録する
   */
  const registMFACode = async (verificationCode: string): Promise<void> => {
    const auth = getAuth();
    if (!verificationCode) {
      throw new Error("認証コードを入力してください。");
    }
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth || !auth.currentUser) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("認証に失敗しました。ログインし直してください。");
    }

    try {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion);
    } catch (error: any) {
      throw new Error("認証に失敗しました1");
    }
    try {
      token.value = await getAuthenticatedTokenAdmin(auth.currentUser);
    } catch (error: any) {
      throw new Error("認証に失敗しました2");
    }
  };
  /**
   * 2段階認証コードをデバイスに送信する
   */
  const sendMFACode = async (): Promise<void> => {
    const auth = getAuth();
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("送信に失敗しました。ログインし直してください。");
    }
    resolver = getMultiFactorResolver(auth, firebaseError.value as MultiFactorError);
    if (!resolver) {
      throw new Error("resolverを作成できませんでした");
    }

    const selectedIndex = 0; // ホントはhintsを見て、多要素認証を選択させるように作るこむといい
    if (resolver.hints[selectedIndex].factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
      // 携帯電話での2段階認証でなければエラー
      throw new Error("電話での2段階認証ではありません");
    }
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    // Send SMS verification code
    verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  };

  /**
   * 2段階認証コードで認証する
   */
  const verifyMFACode = async (verificationCode: string): Promise<void> => {
    const auth = getAuth();
    if (!verificationCode) {
      throw new Error("認証コードを入力してください。");
    }
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("認証に失敗しました。ログインし直してください。");
    }
    resolver = getMultiFactorResolver(auth, firebaseError.value as MultiFactorError);
    if (!resolver) {
      throw new Error("resolverを作成できませんでした");
    }

    try {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // Complete sign-in.
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
      token.value = await getAuthenticatedTokenAdmin(userCredential.user);
    } catch (error: any) {
      throw new Error("認証に失敗しました");
    }
  };

  /**
   * 認証トークンをRefreshトークンを使用して再取得する
   */
  const refreshToken = async (): Promise<void> => {
    await loginStore.refresh();
  };

  const setAuthType = (type: AuthType | null): void => {
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
  };
  const getAuthType = (): string | null | undefined => {
    const authType = useCookie("auth_type", { maxAge: 604800 });
    return authType.value;
  };
  const setAuthProviderType = (provider: AuthProviderType | null): void => {
    const authProvider = useCookie("auth_provider", { maxAge: 604800 });
    authProvider.value = provider;
  };
  const getAuthProviderType = (): string | null | undefined => {
    const authProvider = useCookie("auth_provider", { maxAge: 604800 });
    return authProvider.value;
  };

  const beforeSignIn = () => {
    isNotRegistered.value = false;
  };

  const afterSignIn = (returnPath: string) => {
    // LoginStoreに前回のログイン情報をセットする
    loginStore.prevProviderType = getAuthProviderType();

    setAuthType(null);
    setAuthProviderType(null);
    if (token.value) {
      // GTMのuser_idをリセットするためリロードしながら元の画面に戻る
      reloadNuxtApp({
        path: returnPath,
        ttl: 1000, // デフォルト 10000
      });
    }
  };

  return {
    token,
    isNotRegistered,
    refreshToken,
    firebaseError,
    mfaDevice,
    signOut,
    checkAuthState,
    afterSignInWithRedirect,
    signInWithGoogle,
    signInWithX,
    signInWithFacebook,
    signInWithEmail,
    sendVerifyEmail,
    signUpWithEmailForAdmin,
    signInWithEmailForAdmin,
    setRecaptcha,
    getMFADevice,
    registMFADevice,
    registMFACode,
    sendMFACode,
    verifyMFACode,
    setAuthType,
    getAuthType,
    setAuthProviderType,
    getAuthProviderType,
    afterSignIn,
  };
});
