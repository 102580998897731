import { OwnerInfo } from "./OwnerInfo";
import { PlanInfo } from "./PlanInfo";
import { AgoraInfo } from "./AgoraInfo";
import { Tag } from "./Tag";
import { VideoStreamingTime } from "./VideoStreamingTime";
import { type Library } from "./Library";
import { type LIVE_STATUS } from "~/consts/contentsConfig";

// ライブデータ
// ライブ、ライブスケジュール、アーカイブ動画で共通
export class LiveInfo {
  constructor(
    public id: string,
    public contentsTitle: string, // 動画のタイトル
    public contentsType: string, // コンテンツタイプ。ライブ、ライブスケジュール、アーカイブ動画
    public description: string, // 動画の説明
    public agora: AgoraInfo | undefined, // agora接続情報
    public thumbnailImage: string, // サムネイル画像
    public streamingVideoSettingList: VideoStreamingTime[],
    public tags: Tag[],
    public liveScheduleDateTime: string, // ライブ開始予定時間
    public liveStartDateTime: string, // ライブ開始時間
    public liveEndDateTime: string, // ライブ開始時間
    public liveStatus: LIVE_STATUS, // 配信ステータス
    public viewCount: number, // 閲覧者数
    public shareCount: number, // シェアされた数
    public planOwner: OwnerInfo | undefined, // 配信者情報
    public addMyList: boolean,
    public addMyReservation: boolean,
    public videoStreamingFlag: boolean,

    public plan: PlanInfo | undefined,
    public isFree: boolean,

    public isContract: boolean, // 契約している講座か
    // アーカイブ向け情報
    public contentsUrl: string,
    public moviePlayTimeSec: number,
    public isUnread: boolean,
    // コンテンツキー
    public contentsKey: string,
    public thumbnailKey: string,
    public isOwner: boolean,
    public isGood: boolean, // 自分がいいねしているか
    public goodCount: number, // いいね数
    public libraries: Library[] = [], // TODO:API追加依頼中
    public isComplete: boolean, // 視聴完了か
    public publishStatus: "PUBLISH" | "PRIVATE" | "LIMITED", // 公開設定
    public contentsSubUrl: string, // ファイルパス。contentsUrlにエラーがある場合に使用
    public liveUserId: string, // ライブ配信者のユーザID
  ) {}
}
