import { PlanInfo } from "../entities/PlanInfo";

export function getOwnerIds(planInfo: PlanInfo): string[] {
  return [planInfo.planOwner!.userId, ...planInfo.subOwnerIds];
}

export interface IPlanBillingType {
  name: string; // 表示名
  type: "contract_period" | "only_once"; // 契約タイプ (contract_period: サブスクリプション, only_once: 一度きり)
  contractPeriod?: number; // 契約期間 (contract_periodの場合のみ)
  availableDays?: number; // 有効日数 (only_onceの場合のみ)
}

export const PLAN_PRICE_BILLING_TYPE_LIST: IPlanBillingType[] = [
  // サブスク 1ヶ月、2ヶ月、3ヶ月、4ヶ月、5ヶ月、6ヶ月
  { name: "[サブスク]1ヶ月毎更新", type: "contract_period", contractPeriod: 1 },
  { name: "[サブスク]2ヶ月毎更新", type: "contract_period", contractPeriod: 2 },
  { name: "[サブスク]3ヶ月毎更新", type: "contract_period", contractPeriod: 3 },
  { name: "[サブスク]4ヶ月毎更新", type: "contract_period", contractPeriod: 4 },
  { name: "[サブスク]5ヶ月毎更新", type: "contract_period", contractPeriod: 5 },
  { name: "[サブスク]6ヶ月毎更新", type: "contract_period", contractPeriod: 6 },
  { name: "[サブスク]12ヶ月毎更新", type: "contract_period", contractPeriod: 12 },
  // 一括 1ヶ月、2ヶ月、3ヶ月、4ヶ月、5ヶ月、6ヶ月、無期限
  { name: "[一括]1ヶ月間利用", type: "only_once", contractPeriod: 1 },
  { name: "[一括]2ヶ月間利用", type: "only_once", contractPeriod: 2 },
  { name: "[一括]3ヶ月間利用", type: "only_once", contractPeriod: 3 },
  { name: "[一括]4ヶ月間利用", type: "only_once", contractPeriod: 4 },
  { name: "[一括]5ヶ月間利用", type: "only_once", contractPeriod: 5 },
  { name: "[一括]6ヶ月間利用", type: "only_once", contractPeriod: 6 },
  { name: "[一括]12ヶ月間利用", type: "only_once", contractPeriod: 12 },
  { name: "[一括]無期限", type: "only_once", contractPeriod: 0 },
];

export function getPlanBillingType(type: string, contractPeriod: number): IPlanBillingType | undefined {
  return PLAN_PRICE_BILLING_TYPE_LIST.find(billingType => {
    return billingType.type === type && billingType.contractPeriod === contractPeriod;
  });
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    getOwnerIds: (planInfo: PlanInfo) => string[];
    getPlanBillingType: (type: string, contractPeriod: number) => IPlanBillingType | undefined;
  }
}
