import { useLoginStore } from "~/stores/LoginStore";
import { usePushNotificationStore } from "~/stores/PushNotificationStore";

/**
 * WebPush用処理
 */
export const useWebPush = () => {
  // トークン送信間隔。1時間ごとに送信する
  const TOKEN_SEND_INTERVAL = 1000 * 60 * 60;

  // プッシュ通知store
  const pushNotificationStore = usePushNotificationStore();
  // ログインstore
  const loginStore = useLoginStore();

  // トークン登録用タイマー
  let sentTokenIntevalId: any = null;

  // ログイン状態を調べて、ログインしている場合はwebpush トークンをAPIに送信する
  // トークンを定期的に送信しないと、バックエンド側で14日間でトークンが無効になるため
  const sentToken = async () => {
    if (!pushNotificationStore.notificationSupported()) {
      return;
    }
    try {
      if (loginStore.isLogin()) {
        // 内部で一定時間内は送信しないようにしているのでここで頻繁によびだしても問題ない
        await pushNotificationStore.registerDeviceToken(false);
      }
    } catch (_e) {}
  };

  // サービスワーカーの登録
  const registerServiceWorker = async () => {
    if (!pushNotificationStore.notificationSupported()) {
      return;
    }
    // バッジクリア
    clearBadgeCount();
    try {
      // service workerが登録されていない場合は登録する
      await pushNotificationStore.registerServiceWorker();
    } catch (error: any) {
      console.log(`サービスワーカー登録に失敗: ${error.message}`); // sentry 必要じゃないため、console.logにしました。
    }
    // 起動後は毎回トークン送信処理を呼ぶ。
    sentToken();
  };

  const onVisibilityChange = () => {
    // ページがフォーカスされた場合はトークンを送信する。
    if (document.visibilityState === "visible") {
      sentToken();
    }
  };

  const onMounted = () => {
    // fincsページを開いた際にWebPush数のBadgeをクリアする
    registerServiceWorker();

    // 一定時間ごとにトークンを送信する
    sentTokenIntevalId = setInterval(() => {
      sentToken();
    }, TOKEN_SEND_INTERVAL);

    // visibilitychangeイベントを監視して、フォーカスされた場合にトークンを送信する
    document.addEventListener("visibilitychange", onVisibilityChange);
  };

  const onUnmounted = () => {
    if (sentTokenIntevalId) {
      clearInterval(sentTokenIntevalId);
      sentTokenIntevalId = null;
    }

    // visibilitychangeイベントを削除
    document.removeEventListener("visibilitychange", onVisibilityChange);
  };

  return {
    onMounted,
    onUnmounted,
  };
};
