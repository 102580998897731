import {
  getDoc,
  getDocs,
  getDocFromServer,
  getDocFromCache,
  getDocsFromServer,
  getDocsFromCache,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
} from "firebase/firestore";

export const getFirestoreDocs = async (query: Query): Promise<QuerySnapshot<any>> => {
  try {
    return await getDocs(query);
  } catch (error: any) {
    reloadOnFirebaseError(error);
    throw error;
  }
};

export const getFirestoreDoc = async (reference: DocumentReference): Promise<DocumentSnapshot<any>> => {
  try {
    return await getDoc(reference);
  } catch (error: any) {
    reloadOnFirebaseError(error);
    throw error;
  }
};

export const getFirestoreDocsFromCacheOrServer = async (query: Query): Promise<QuerySnapshot<any>> => {
  let snapshot: QuerySnapshot<any> | undefined;
  try {
    // キャッシュから取得
    snapshot = await getDocsFromCache(query);
  } catch (error: any) {
    showConsoleLog("[fs debug] getDocsFromCache error", error);
  }
  if (!snapshot || snapshot.empty) {
    // キャッシュがない場合はサーバーから取得。
    // getDocsFromCacheはキャッシュから取得できなかった場合でもerrorが発生しないのでemptyでチェックする
    // 本当のemptyとキャッシュがない場合のemptyを区別することができないので何か工夫が必要
    // また取得個数が 7 件以下の場合はキャッシュされない模様（実績ベース）
    // メッセージ数が少ない場合はキャッシュされないので注意
    showConsoleLog("[fs debug] getDocsFromServer");
    snapshot = await getDocsFromServer(query);
  }

  if (!snapshot) {
    showConsoleLog("[fs debug] getDocsFromServer 取得できませんでした。", query);
  } else if (snapshot.metadata.fromCache) {
    showConsoleLog("[fs debug] getDocsFromServer キャッシュから取得", query);
  } else {
    showConsoleLog("[fs debug] getDocsFromServer オンラインから取得", query._query.path.segments);
  }

  return snapshot!;
};

export const getFirestoreDocFromCacheOrServer = async (docRef: DocumentReference): Promise<DocumentSnapshot<any>> => {
  let docSnap: any;
  try {
    // キャッシュから取得
    docSnap = await getDocFromCache(docRef);
  } catch (error: any) {
    showConsoleLog("[fs debug] getDocFromCache error", error);
  }
  if (!docSnap) {
    // キャッシュがない場合はサーバーから取得。
    // キャッシュから取得できなかった場合はgetDocFromCacheでerrorが発生する
    showConsoleLog("[fs debug] getDocFromServer");
    docSnap = await getDocFromServer(docRef);
  }
  if (!docSnap) {
    showConsoleLog("[fs debug] getDocFromCacheOrServer 取得できませんでした。", docRef);
  } else if (docSnap.metadata.fromCache) {
    showConsoleLog("[fs debug] getDocFromCacheOrServer キャッシュから取得", docRef);
  } else {
    showConsoleLog("[fs debug] getDocFromCacheOrServer オンラインから取得", docRef);
  }
  return docSnap;
};

const SHOW_CONSOLE_LOG = false; // デバッグ時は true にする
function showConsoleLog(...data: any[]) {
  if (SHOW_CONSOLE_LOG) {
    consoleLog(data);
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    getFirestoreDocs: (query: Query) => Promise<QuerySnapshot<any>>;
    getFirestoreDoc: (reference: DocumentReference) => Promise<DocumentSnapshot<any>>;
    getFirestoreDocsFromCacheOrServer: (query: Query) => Promise<QuerySnapshot<any>>;
    getFirestoreDocFromCacheOrServer: (docRef: DocumentReference) => Promise<DocumentSnapshot<any>>;
  }
}
