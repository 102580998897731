import { defineStore } from "pinia";

interface TalkRoomListView {
  isOpen: boolean;
  onlyUnread: boolean;
}
interface PlanTalkRoomView {
  groupTalkRoomList: TalkRoomListView;
  dMTalkRoomList: TalkRoomListView;
  archiveTalkRoomList: TalkRoomListView;
}

export const useTalkRoomViewStore = defineStore(
  "TalkRoomViewStore",
  () => {
    // 講座ごとのトークルームビュー状態
    const planTalkRoomViews = ref<{ planId: string; planTalkRoomView: PlanTalkRoomView }[]>([]);
    // トークルームごとのメッセージ入力中テキスト
    const talkRoomMessageInputs = ref<
      {
        talkRoomId: string;
        message: string | any; // string は、旧式データの互換性確保のため
      }[]
    >([]);
    // FirestoreCache 有効/無効 (default: true)
    const isFirestoreCacheEnabled = ref(true);

    // 初期化
    const reset = () => {
      planTalkRoomViews.value = [];
      talkRoomMessageInputs.value = [];
      isFirestoreCacheEnabled.value = true;
    };

    // デフォルト値
    const defaultPlanTalkRoomView = () => {
      return {
        groupTalkRoomList: { isOpen: true, onlyUnread: false },
        dMTalkRoomList: { isOpen: true, onlyUnread: false },
        archiveTalkRoomList: { isOpen: false, onlyUnread: false },
      } as PlanTalkRoomView;
    };

    // 講座のトークルームビュー状態を取得。なければデフォルトで初期化して返す
    const getOrDefaultPlanTalkRoomView = (planId: string) => {
      let planTalkRoomView = planTalkRoomViews.value.find(item => item.planId === planId)?.planTalkRoomView;
      if (!planTalkRoomView) {
        planTalkRoomView = defaultPlanTalkRoomView();
        planTalkRoomViews.value.push({ planId, planTalkRoomView });
      }
      return planTalkRoomView;
    };

    // 各トークルームリストの開閉状態
    const isGroupTalkRoomListOpen = (planId: string) => {
      return getOrDefaultPlanTalkRoomView(planId).groupTalkRoomList.isOpen;
    };
    const setGroupTalkRoomListOpen = (planId: string, isOpen: boolean) => {
      getOrDefaultPlanTalkRoomView(planId).groupTalkRoomList.isOpen = isOpen;
    };
    const isDMTalkRoomListOpen = (planId: string) => {
      return getOrDefaultPlanTalkRoomView(planId).dMTalkRoomList.isOpen;
    };
    const setDMTalkRoomListOpen = (planId: string, isOpen: boolean) => {
      getOrDefaultPlanTalkRoomView(planId).dMTalkRoomList.isOpen = isOpen;
    };
    const isArchiveTalkRoomListOpen = (planId: string) => {
      return getOrDefaultPlanTalkRoomView(planId).archiveTalkRoomList.isOpen;
    };
    const setArchiveTalkRoomListOpen = (planId: string, isOpen: boolean) => {
      getOrDefaultPlanTalkRoomView(planId).archiveTalkRoomList.isOpen = isOpen;
    };

    // 各トークルームリストの未読フィルタ
    const onlyUnreadGroupTalkRooms = (planId: string) => {
      return getOrDefaultPlanTalkRoomView(planId).groupTalkRoomList.onlyUnread;
    };
    const setOnlyUnreadGroupTalkRooms = (planId: string, onlyUnread: boolean) => {
      getOrDefaultPlanTalkRoomView(planId).groupTalkRoomList.onlyUnread = onlyUnread;
    };
    const onlyUnreadDMTalkRooms = (planId: string) => {
      return getOrDefaultPlanTalkRoomView(planId).dMTalkRoomList.onlyUnread;
    };
    const setOnlyUnreadDMTalkRooms = (planId: string, onlyUnread: boolean) => {
      getOrDefaultPlanTalkRoomView(planId).dMTalkRoomList.onlyUnread = onlyUnread;
    };

    return {
      planTalkRoomViews,
      talkRoomMessageInputs,
      isFirestoreCacheEnabled,
      reset,
      isGroupTalkRoomListOpen,
      setGroupTalkRoomListOpen,
      isDMTalkRoomListOpen,
      setDMTalkRoomListOpen,
      isArchiveTalkRoomListOpen,
      setArchiveTalkRoomListOpen,
      onlyUnreadGroupTalkRooms,
      setOnlyUnreadGroupTalkRooms,
      onlyUnreadDMTalkRooms,
      setOnlyUnreadDMTalkRooms,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
